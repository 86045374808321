
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { setNowPlaying, toggleContext } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';

const dbFuncs = require('../../Test/dbShorthand.js');

const sources = ['Home','Sounds','Sights','Synthesis','Store'];
const contextOptions = ['Add to Playlist','Follow Artist','Go To Page', 'Buy'];

class ConnectedStreamingOptions extends React.Component {

    constructor (props) {
      super(props);
      this.state = ({
          sectionInfo: {
            section: '',
            dropdownActive: false
        }
      });
    }

    playSelectedSong = (songLink) => {
      this.props.setNowPlaying(songLink);
    }

    toggleDropdown = (e, service) => {
      this.props.toggleContext({options: contextOptions,
                                width: 128,
                                height: 128,
                                position: { x: e.clientX, y: e.clientY },
                                type: service,
                                visible: (this.props.navState.nowPlaying !== undefined
                                          && this.props.song.name === this.props.navState.nowPlaying.name
                                          && this.props.navState.contextMenu.visible) ? false: true});
    }

    render() {
      let buttonImg = "hamburger";
      if (this.props.navState.artists.siteStyle.theme === "dark") buttonImg += 'w';
      return(
          <div className={"streamingOptions"}>
              <img onClick={() => this.playSelectedSong(
                {...this.props.song,
                 ...{playing: (this.props.navState.nowPlaying === undefined) ? true: !this.props.navState.nowPlaying.playing,
                     artist: this.props.navState.artists.artist_name,
                     favoriteColor: this.props.navState.artists.favoriteColor}})}
                   src={(this.props.navState.nowPlaying !== undefined &&
                         this.props.navState.nowPlaying.name === this.props.song.name)
                         ? ((this.props.navState.artists.siteStyle.theme === "dark") ? this.props.streamingIcons.pauseButtonw.src : this.props.streamingIcons.pauseButton.src)
                         : ((this.props.navState.artists.siteStyle.theme === "dark") ? this.props.streamingIcons.playButtonw.src  : this.props.streamingIcons.playButton.src)}
                   className={"streamingButton"}/>
              <div className={"streamingButton"}
                   onClick={(e) => { e.preventDefault(); this.toggleDropdown(e,"streaming"); }}>
                <img style={{width: '16px', height: '16px', left: 0, right: 0, top: 0, bottom: 0, margin: 'auto'}} src={this.props.streamingIcons[buttonImg].src}/>
              </div>
          </div>
        )
      }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  toggleContext: context => dispatch(toggleContext(context)),
  setNowPlaying: nowPlaying => dispatch(setNowPlaying(nowPlaying))
})

const StreamingOptions = connect(mapStateToProps,mapDispatchToProps)(ConnectedStreamingOptions)

export default StreamingOptions;
