import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, updateArtists, setNowPlaying } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';
import StreamingOptions from './StreamingOptions.js'

const dbFuncs = require('../../Test/dbShorthand.js');

const sources = ['Home','Sounds','Sights','Synthesis','Store'];

class ConnectedSongBox extends React.Component {

    constructor (props) {
      super(props);
      this.state = { selectedSong: ''}
    }

    render() {
        return(
          <div className={"songBox"}>
            <div className={"songBoxNumber"}>{this.props.song.trackNumber}</div>
            <img src={(this.props.song.image === undefined) ? this.props.albumCover : this.props.song.image} className={"songBoxImage"}/>
            <div className={"songBoxName"}>
              {this.props.song.name}
            </div>
            <StreamingOptions streamingIcons={this.props.streamingIcons} song={this.props.song}/>
          </div>
        )
      }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateNavbar: section => dispatch(updateNavbar(section)),
  updateArtists: artistInfo => dispatch(updateArtists(artistInfo)),
  setNowPlaying: songPlaying => dispatch(setNowPlaying(songPlaying))
})

const SongBox = connect(mapStateToProps,mapDispatchToProps)(ConnectedSongBox)

export default SongBox;
