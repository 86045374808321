import { UPDATE_SEARCH_QUERY,
  UPDATE_NAVBAR,
  SET_PARAMS,
  GET_SPOTIFY_USER,
  GET_INSTAGRAM_USER,
  GET_SPOTIFY_STREAMING,
  SET_SPOTIFY_LOADED,
  SET_TOKENS_RETRIEVED,
  SET_TOP_TRACKS,
  SET_TRACKS_RETRIEVED,
  UPDATE_SCROLL_POSITION,
  UPDATE_WINDOW_SIZE,
  UPDATE_ARTISTS,
  SET_NOW_PLAYING,
  UPDATE_COMPONENT_COUNT,
  UPDATE_PLAYER_DATA,
  UPDATE_COMIC_PAGE,
  UPDATE_COMIC_FRAME,
  UPDATE_COMIC_PLAY,
  CONFIRM_CONTENT_LOADED,
  PLAYING_VIDEO,
  TOGGLE_CONTEXT
} from './action-types'

export const updateNavbar = (payload) => dispatch => {
 dispatch({
  type: UPDATE_NAVBAR,
  payload: payload
})
}
export const setParams = (payload) => dispatch => {
 dispatch({
  type: SET_PARAMS,
  payload: payload
})
}
export const getSpotifyUserData = (payload) => dispatch => {
 dispatch({
  type: GET_SPOTIFY_USER,
  payload: payload
})
}
export const getSpotifyStreamingData = (payload) => dispatch => {
 dispatch({
  type: GET_SPOTIFY_STREAMING,
  payload: payload
})
}
export const getInstagramUserData = (payload) => dispatch => {
 dispatch({
  type: GET_INSTAGRAM_USER,
  payload: payload
})
}
export const setSpotifyLoaded = (payload) => dispatch => {
 dispatch({
  type: SET_SPOTIFY_LOADED,
  payload: payload
})
}
export const setTokensRetrieved = (payload) => dispatch => {
 dispatch({
  type: SET_TOKENS_RETRIEVED,
  payload: payload
})
}
export const setTopTracks = (payload) => dispatch => {
 dispatch({
  type: SET_TOP_TRACKS,
  payload: payload
})
}
export const setTracksRetrieved = (payload) => dispatch => {
 dispatch({
  type: SET_TRACKS_RETRIEVED,
  payload: payload
})
}
export const updateScrollPosition = (payload) => dispatch => {
 dispatch({
  type: UPDATE_SCROLL_POSITION,
  payload: payload
})
}
export const updateWindowSize = (payload) => dispatch => {
 dispatch({
  type: UPDATE_WINDOW_SIZE,
  payload: payload
})
}
export const updateSearchQuery = (payload) => dispatch => {
 dispatch({
  type: UPDATE_SEARCH_QUERY,
  payload: payload
})
}
export const updateArtists = (payload) => dispatch => {
 dispatch({
  type: UPDATE_ARTISTS,
  payload: payload
})
}
export const setNowPlaying = (payload) => dispatch => {
 dispatch({
  type: SET_NOW_PLAYING,
  payload: payload
})
}
export const updateComponentCount = (payload) => dispatch => {
 dispatch({
  type: UPDATE_COMPONENT_COUNT,
  payload: payload
})
}
export const updatePlayerData = (payload) => dispatch => {
 dispatch({
  type: UPDATE_PLAYER_DATA,
  payload: payload
})
}
export const updateComicFrame = (payload) => dispatch => {
 dispatch({
  type: UPDATE_COMIC_FRAME,
  payload: payload
})
}
export const updateComicPage = (payload) => dispatch => {
 dispatch({
  type: UPDATE_COMIC_PAGE,
  payload: payload
})
}
export const updateComicPlay = (payload) => dispatch => {
 dispatch({
  type: UPDATE_COMIC_PLAY,
  payload: payload
})
}
export const confirmContentLoaded = (payload) => dispatch => {
 dispatch({
  type: CONFIRM_CONTENT_LOADED,
  payload: payload
})
}
export const playingVideo = (payload) => dispatch => {
 dispatch({
  type: PLAYING_VIDEO,
  payload: payload
})
}
export const toggleContext = (payload) => dispatch => {
 dispatch({
  type: TOGGLE_CONTEXT,
  payload: payload
})
}
