import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, updateArtists } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';

const dbFuncs = require('../../Test/dbShorthand.js');

const sources = ['Home','Sounds','Sights','Synthesis','Store'];

class ConnectedSocialBox extends React.Component {

    constructor (props) {
      super(props);
      this.state = ({});
    }

    render() {
        let fbLogo, inLogo, twLogo, spLogo, apLogo, soLogo;
        if (this.props.artistInfo.siteStyle.theme === "dark") {
          fbLogo = "facebookw";
          inLogo = "instagramw";
          twLogo = "twitterw";
          spLogo = "spotifyw";
          apLogo = "applew";
          soLogo = "soundcloudw";
        } else {
          fbLogo = "facebook"; inLogo = "instagram"; twLogo = "twitter"; spLogo = "spotify"; apLogo = "apple"; soLogo = "soundcloud";
        }


        if (this.props.artistInfo === undefined) return (<div></div>)
        return(
          <div style={{opacity: (this.props.fullScreen) ? 0 : 1,
               zIndex: 1500, margin: "auto", height: "45px", position: "absolute", bottom: '0px', left: '0px', width: "100%", alignItems: "center", display: "flex", flexFlow: "row"}}>
          { this.props.artistInfo.social.map((item, i) => {
            let iconPath = item.service+((this.props.artistInfo.siteStyle.theme === "dark") ?'w':'');
            return(<a key={i} style={{margin: "auto"}} target="_blank" href={item.url}>
              <img className={'socialIcon'}
                   src={this.props.icons[iconPath].src}/>
            </a>);
          })
          }
        </div>
        )
      }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateArtists: artistInfo => dispatch(updateArtists(artistInfo))
})

const SocialBox = connect(mapStateToProps,mapDispatchToProps)(ConnectedSocialBox)

export default SocialBox;
