import React from 'react'
import { setCookie, getCookie } from 'redux-cookie'

import store from './store/index.js'
import { connect } from 'react-redux'
import * as d3 from "d3"
import ReactDOM from 'react-dom';
import { setNowPlaying } from './actions/simpleAction.js'
const dbFuncs = require('./Test/dbShorthand.js')


const convertToKey = (keyInt) => {
  switch ( keyInt ) {
    case (0): return 'C';
    case (1): return 'C♯/D♭';
    case (2): return 'D';
    case (3): return 'D♯/E♭';
    case (4): return 'E';
    case (5): return 'F';
    case (6): return 'F♯/G♭';
    case (7): return 'G';
    case (8): return 'G♯/A♭';
    case (9): return 'A';
    case (10): return 'A♯/B♭';
    case (11): return 'B';
  }
}
const msToMinute = (ms) => {
  let totalSeconds = ms/1000;
  let minutes = Math.floor(totalSeconds/60);
  let seconds = dbFuncs.pad(Math.floor((totalSeconds/60 - minutes) * 60),2);

  return minutes+":"+seconds;
}
const arrayTransform = (data,formatDate) => {

    const parseTime = d3.timeParse(formatDate);
    let result = [];
    data.forEach((d) => {
        d.columns.slice(0).forEach((i) => {
            result.push({
                columnKey: i,
                date: d.date,
                popularity: d[i],
                id: d.id
            });
        });
    });
    return result;
}

class ConnectedInfoGraph extends React.Component {

    constructor(props) {
      super(props);
      this.drawChart = this.drawChart.bind(this);
      this.parseData = this.parseData.bind(this);

      this.state = {
        height: 250,
        width: 500,
        margins: { top: 25, right: 50, bottom: 25, left: 25 },
                     width: 475,
                     height: 250 }
    }

    componentDidMount() {
      this.drawChart('Popularity','%Y%m%d');
      this.getTrackFeatures();
    }

    getTrackFeatures = () => {
      let trackFeatures = [];
      dbFuncs.process('cataphonic','tracks','distinct',"id",{ source_id: { $eq: this.props.artist_id }})
      .then( d => {
          d.map((item, i) => {dbFuncs.process('cataphonic','analysis','findOne',{ id: item })
          .then( f => trackFeatures.push(f) );
          }
        )
      })
      this.setState( { trackFeatures: trackFeatures } )
    }

    highlightLines = (d) => {
      let lastItem = this.props.artistStats.length-1;

      this.props.artistStats[lastItem].tracks.map((item, i) => {
        if (i === d) return;
        d3.select("."+this.props.chartID+"line"+i).transition().duration(200).style("stroke","lightgrey").style("opacity","0.2");
      })
    }

    restoreLines = (line, colors) => {
      let lastItem = this.props.artistStats.length-1;
      this.props.artistStats[lastItem].tracks.map((item, i) => {
          if (colors ) d3.select("."+this.props.chartID+"line"+i).transition().duration(200).style("stroke",colors[i].color).style("opacity","1");
      })
    }

    parseData = () => {


      let dataPairs = [];

      for (let i = 0; i < this.props.artistStats.length; i += 1){

        let dataSet = {}

        dataSet['date'] = new Date(this.props.artistStats[i]._id)
        dataSet['columns'] = []

        for (let j = 0; j < this.props.artistStats[this.props.artistStats.length-1].tracks.length; j += 1)
        {
          if (this.props.artistStats[i].tracks[j]) {
            dataSet['columns'].push(this.props.artistStats[i].tracks[j].name);
            dataSet[this.props.artistStats[i].tracks[j].name] = { id: this.props.artistStats[i].tracks[j].id, popularity: this.props.artistStats[i].tracks[j].popularity};
          }
        }
        dataPairs.push(dataSet);
      }
      dataPairs = arrayTransform(dataPairs,'%Y%m%d')

      return dataPairs;
    }

    drawChart = (legendAxisY, dateFormat) => {

      var rawData = this.props.artistStats;
      var data = this.parseData()
      if (!data[0]) return;


      const fields = Object.keys(data[0]);
      const objKey = fields[0];
      const objX   = fields[1];
      const objY   = fields[2];
      const feats  = fields[3];

      const dataGroup = d3.nest().key((d) => d[objKey]).entries(data);


      const svg = d3.select(ReactDOM.findDOMNode(this))
                    .append('svg')
                    .attr("height", this.state.height)
                    .attr("width", this.state.width);

      const div = d3.select(ReactDOM.findDOMNode(this)).append("div")
          .attr("class", "tooltip")
          .style("opacity", 0)

      const xScale = d3.scaleTime();
      const yScale = d3.scaleLinear();

      xScale.domain(d3.extent(data, (d) => d[objX]));

      //Find max value on Y axisLeft

      var yMin = (this.props.artistStats[0].minPopularity - 8 > 0) ? this.props.artistStats[0].minPopularity-8 : 0;
      var yMax = this.props.artistStats[0].maxPopularity+8;

      yScale.domain(d3.extent([yMin,yMax]));
      xScale.range([this.state.margins.left, this.state.width - this.state.margins.right/2]);
      yScale.range([this.state.height - this.state.margins.bottom, this.state.margins.top])

      let numTicks = 0;

      switch (true) {
        case ( rawData.length > 5) : numTicks = 5; break;
        default: numTicks = rawData.length; break;
      }
      const xAxis = d3.axisBottom(xScale)
                      .ticks(numTicks)

      const yAxis = d3.axisLeft(yScale);

      const parseTime = d3.timeFormat(dateFormat);

      const lineGen = d3.line().x((d) => xScale(d[objX])+this.state.margins.bottom/2).y((d) => yScale(d[objY]['popularity'])).curve(d3.curveMonotoneX);

      const colors = [];

      svg.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + (this.state.height - this.state.margins.bottom) + ")")
          .call(xAxis) // Drawing axis X

      svg.append("g")
          .attr("class", "y axis")
          .attr("transform", "translate(" + (this.state.margins.left) + ",0)")
          .call(yAxis) // Drawing axis Y
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("fill", "#000")
          .attr("y", 10)
          .text(legendAxisY); // Text column Y

      dataGroup.forEach((d, i) => {

          svg.append('path')
              .attr("class", () => this.props.chartID+"line" + i)
              .attr('d', lineGen(d.values)) // Drawing line
              .attr('stroke', (d, j) => { let color = "hsl(" + i * 50 % 360 + ",100%,50%)";
                                          colors.push({ color: color});
                                          return color; })  // Setting color
              .attr('stroke-width', 3)
              .attr('fill', 'none')
              .on("mouseover",(f) => {
                this.highlightLines(i);
              })
              .on("mouseout",(f) => {
                if (colors.length > 0) this.restoreLines(i,colors);
              })

          svg.append('text')
              .attr("class", "textKey" + i)
              .attr("transform", "translate(" + (xScale(d.values[d.values.length - 1][objX])+40.5) + "," + yScale(this.state.width - this.state.margins.right + i ) + ")")
              .style("font", "10px sans-serif")
              .text(d.key); // Showing data keys

          svg.selectAll("dot")
              .data(d.values)
              .enter().append("circle")
              .attr("r", 3)
              .attr("class", "circle")
              .attr("opacity",0.2)
              .attr("cx", (d) => xScale(d[objX])+this.state.margins.left/2)
              .attr("cy", (d) => yScale(d[objY]['popularity']))
              .on("click", (d) => {
                store.dispatch(setCookie('last_playing',d[objY]['id']));

                if (store.dispatch(getCookie('s_tfa'))===undefined) {
                  const spotifyRefreshURL     = 'http://cataphonic.studio:8888/spotifyLogin';
                  window.location.assign(spotifyRefreshURL);
                }
                else {
                  this.props.setNowPlaying({ id: d[objY]['id'], name: d[objKey]});
                };
              })
              .on("mouseover", (d) => {
                var x = d3.event.clientX;
                var y = d3.event.clientY;

                div.style("position", 'absolute');
                div.style("background-color", "white");
                div.style("hidden", 'false');
                div.style("opacity", 1);
                div.style("height","auto");
                div.style("left", (x + 20) + "px")
                div.style("top" , (y - 28) + "px")
                div.html('');
                  dbFuncs.process('cataphonic','features','findOne',{ id: d[objY]['id'] })
                  .then( f => { this.highlightLines(i);
                                div.html("<b>Date: </b>" + parseTime(d[objX]) +
                                         "<br/><b>Track: </b>" + d[objKey]    +
                                         "<br/><b>"+legendAxisY + ":</b> " + d[objY]['popularity'] +
                                         "<br/><b>Key: </b>"+convertToKey(f.key) +
                                         "<br/><b>Time Signature: </b>"+f.time_signature +
                                         "<br/><b>Tempo: </b>"+f.tempo +
                                         "<br/><b>Length: </b>"+msToMinute(f.duration_ms))
                              })
                  .catch( e => {
                                div.html("<b>Date: </b>" + parseTime(d[objX]) + "<br/><b>ID: </b>"+d[feats]+"<br/><b>Track: </b>" + d[objKey] +"<br/><b>"+legendAxisY + ":</b> " + d[objY])
                  })
              })
              .on("mouseout", (d) => {
                  this.restoreLines(i,colors);
                  div.style("position",'relative');
                  div.style("opacity", 0);
                  div.style("hidden", 'true');
                  div.style("height", 0);
              });

            })




  }

    render(){
      return <div id={"#" + this.props.id}></div>
    }
  }

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
      setNowPlaying: nowPlaying => dispatch(setNowPlaying(nowPlaying))
})

const InfoGraph = connect(mapStateToProps, mapDispatchToProps)(ConnectedInfoGraph);
export default InfoGraph;
