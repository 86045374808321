import React, { Component } from 'react';
import LogoBox from './LogoBox.js'
import NavBox from './NavBox.js'
import { connect } from 'react-redux';
import { updateNavbar } from './actions/simpleAction.js'

class ConnectedSiteHeader extends Component {

  constructor(props){
    super(props);

    this.pageScrolled    = this.pageScrolled.bind(this);
    this.getColor = this.getColor.bind(this);
  }

  pageScrolled = (e) => {
    console.log(e.scrollTop);
  }

  renderBackboard = () => {
      return (
        <div className={"headerBackboard"}
             style={{
                     backgroundColor: this.getColor(),
                      opacity: (this.props.navState.playingVideo
                                 || this.props.navState.section.split('/')[0] === "PWRVOL"
                                 || this.props.navState.section.split('/')[0] === "SCATTEREDBIRDS"
                                 || this.props.navState.section.split('/')[0] === "UNIQUEUNKNOWN"
                                 || this.getColor("T") === "transparent")
                                ? 0
                                : (this.getColor() === "white")
                                  ? 0.4
                                  : 1}}>
        </div>
      )
  }

  getColor = (type) => {
    if (type === "T") {
    } else {
      switch(this.props.navState.section) {
      case ("Sights"):    return (type === "Logo") ? "white" : "black"; break;
      case ("Synthesis"): return (type === "Logo") ? "white" : "black"; break;
      case ("Coalition"): return (type === "Logo") ? "white" : "black"; break;
      case ("Home"):      return (type === "Logo") ? "black" : "white"; break;
      case ("Store"):     return (type === "Logo") ? "white" : "black"; break;
      case ("Sounds"):    return (type === "Logo") ? "white" : "black"; break;
      default:            return (type === "Logo")
                                  ? ( (this.props.navState.artists
                                    && this.props.navState.artists.siteStyle
                                    && this.props.navState.artists.siteStyle.theme === "dark") ? "white" : "black")
                                  : ( (this.props.navState.artists
                                    && this.props.navState.artists.siteStyle
                                    && this.props.navState.artists.siteStyle.theme === "dark") ? "black" : "white"); break;
      }
    }
}



  componentDidMount() {
    window.addEventListener('onScroll', this.pageScrolled);
  }

  render() {
    if (this.props.icons !== undefined) {
    return(
      <div className={"siteHeader"}>
        {this.renderBackboard()}
        <LogoBox color={this.getColor("Logo")}/>
        <NavBox icons={this.props.icons} color={this.getColor("")} history={this.props.history}/>
      </div>
    )
  } else return <React.Fragment/>
}

}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
  updateNavbar: section => dispatch(updateNavbar(section))
});

const SiteHeader = connect(mapStateToProps, mapDispatchToProps)(ConnectedSiteHeader);

export default SiteHeader;
