import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import { updateNavbar, confirmContentLoaded } from '../../actions/simpleAction.js'
import store from '../../store/index.js';

const dbFuncs = require('../../Test/dbShorthand.js');

class ConnectedAuthCallback extends Component {

  constructor(props){
    super(props);
    this.state = {status: ''}
  }

  sendData = async () => {
    let user = store.dispatch(getCookie('un')).username;
    let service = window.location.href.split('?service=')[1].split('&')[0];
    let key = window.location.href.split('&k=')[1].split('#')[0];
    let at  = window.location.href.split('access_token=')[1];
    let payload = new Object;

    payload[service] = new Object;
    payload['key'] = key;
    payload[service] = {
      access_token: at
    }

    let sendInfo = await dbFuncs.process('cataphonic','user_auth','updateOne',payload,{username: user})
    if (sendInfo.match !== undefined) {
      this.setState({status: "Failed to authenticate, try again."});
    } else {
      this.setState({status: "Succeeded.  Returning to page."})
    }
    this.timeout = setTimeout(() => {
      window.close();
      clearTimeout(this.timeout);
    }, 300);

  }

  render = () => {

      if (this.state.status === ''){
        this.setState({status: 'Sending..'});
        this.sendData();
      }

      return(
        <div>
        {this.state.status}
        </div>
      )
    }
}
const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    updateNavbar: section => dispatch(updateNavbar(section)),
    confirmContentLoaded: content => dispatch(confirmContentLoaded(content))
})

const AuthCallback = connect(mapStateToProps, mapDispatchToProps)(ConnectedAuthCallback);

export default AuthCallback;
