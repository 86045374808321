import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePlayerData } from './actions/simpleAction';


var dbFuncs = require('./Test/dbShorthand.js')

function gcd (a, b) {
  var temp = 0;
  var m = 0;

  if (b > a) { temp = a; a = b; b = temp; }
  while ( b != 0) {
    m = a%b; a = b; b = m;
  }
  return a.toFixed(2);
}

function ratio ( x, y) {
    var c = gcd(x, y);
    return ""+(x/c)+":"+(y/c);
}

class ConnectedDDDDStatsPlayer extends Component {


  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className={"DDDDPlayerStatsPanel"}>
        <div className={"DDDDUsernameHeader"}>{this.props.Username}                        </div>
        <br/>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: (this.props.CurrentlyOnline != 0) ? "#90ad6f" : "#bdbbb9"}} className={"DDDDStatDescriptionOne"}>  <b>{(this.props.CurrentlyOnline == 1 ) ? "Online" : "Last Online:   " + this.props.LastOnline}</b> </div>
        </div>
        <br/>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#87b090"}} className={"DDDDStatDescriptionThree"}> <b>Knock-Outs</b>     <br/>{(!this.props.KOs)     ? "0" : this.props.KOs}     </div>
          <div style={{backgroundColor: "#8796b0"}} className={"DDDDStatDescriptionThree"}> <b>Assists</b>        <br/>{(!this.props.Assists) ? "0" : this.props.Assists} </div>
          <div style={{backgroundColor: "#b08887"}} className={"DDDDStatDescriptionThree"}> <b>Downs</b>          <br/>{(!this.props.Downs)   ? "0" : this.props.Downs}   </div>
        </div>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#bdbbb9"}} className={"DDDDStatDescriptionOne"}> <b>K/D Ratio (%)</b>    <br/>{(this.props.Downs == 0 || !this.props.Downs) ? ((this.props.KOs) ? this.props.KOs+":0" : "0:0")
                                                                                                                                                                     : ((parseInt(this.props.KOs)/parseInt(this.props.Downs)).toFixed(2)+":1")/*ratio(parseInt(this.props.KOs),parseInt(this.props.Downs))*/} </div>
        </div>
        <br/>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#8796b0"}} className={"DDDDStatDescriptionOne"}> <b>Games Played</b>     <br/>{(!this.props.GamesPlayed) ? "0" : this.props.GamesPlayed} </div>
        </div>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#87b090"}} className={"DDDDStatDescriptionTwo"}> <b>Won</b>              <br/>{(!this.props.GamesWon)    ? "0" : this.props.GamesWon}    </div>
          <div style={{backgroundColor: "#b08887"}} className={"DDDDStatDescriptionTwo"}> <b>Lost</b>             <br/>{(this.props.GamesWon)   ? (parseInt(this.props.GamesPlayed) - parseInt(this.props.GamesWon))
                                                                                                                                                : ((!this.props.GamesLost) ? ((!this.props.GamesPlayed) ? 0
                                                                                                                                                                                                        : this.props.GamesPlayed)
                                                                                                                                                                           : this.props.GamesLost)
                                                                                                                                                                          } </div>
        </div>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#bdbbb9"}} className={"DDDDStatDescriptionOne"}> <b>Win Rate (%)</b>        <br/>{( this.props.GamesPlayed > 0) ? (Math.round(parseInt(this.props.GamesWon) / parseInt(this.props.GamesPlayed) *100)) + "\%" : "0\%" } </div>
        </div>
        <br/>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#7b818a"}} className={"DDDDStatDescriptionTwo"}>  <b>Shots Taken</b>    <br/>{(!this.props.ShotsTaken)   ? "0" : this.props.ShotsTaken}      </div>
          <div style={{backgroundColor: "#7b818a"}} className={"DDDDStatDescriptionTwo"}>  <b>Points Scored</b>  <br/>{(!this.props.PointsScored) ? "0" : this.props.PointsScored}    </div>
        </div>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#a1a6ad"}} className={"DDDDStatDescriptionThree"}> <b>Two-Pointers</b>   <br/>{(!this.props.TwoPointers)   ? "0" : this.props.TwoPointers}     </div>
          <div style={{backgroundColor: "#a1a6ad"}} className={"DDDDStatDescriptionThree"}> <b>Three-Pointers</b> <br/>{(!this.props.ThreePointers) ? "0" : this.props.ThreePointers}   </div>
          <div style={{backgroundColor: "#a1a6ad"}} className={"DDDDStatDescriptionThree"}> <b>Dunks</b>          <br/>{(!this.props.Dunks)         ? "0" : this.props.Dunks}           </div>
        </div>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#bdbbb9"}} className={"DDDDStatDescriptionOne"}>  <b>Accuracy</b> <br/>{ (this.props.ShotsTaken == 0 || !this.props.ShotsTaken) ?"0\%" :
                                                                                                                  Math.round((parseInt(this.props.TwoPointers,10) +
                                                                                                                  parseInt(this.props.ThreePointers,10) +
                                                                                                                  parseInt(this.props.Dunks))/parseInt(this.props.ShotsTaken) * 100)+"\%"} </div>
        </div>
        <br/>
        <div className={"DDDDStatsPlayerSubPanel"}>
          <div style={{backgroundColor: "#d1d0ba"}} className={"DDDDStatDescriptionOne"}>  <b>Date Created</b>   <br/>{this.props.DateCreated}</div>
        </div>
      </div>
    )
  }

}

const mapDispatchToProps = dispatch => ({
    updatePlayerData: player => dispatch(updatePlayerData(player))
})

const mapStateToProps = state => ({
 ...state
})

const DDDDStatsPlayer = connect(mapStateToProps, mapDispatchToProps)(ConnectedDDDDStatsPlayer);
export default DDDDStatsPlayer;
