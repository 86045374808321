import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateNavbar, updateScrollPosition, playingVideo, toggleContext } from './actions/simpleAction.js'
import Logo from './icons/C Wave 3.png'
import Logo2 from './icons/CWaveWhite.png'

const contextOptions = ['Go Home','Go Fullscreen'];

class ConnectedLogoBox extends Component {

  constructor(props){
    super(props);

    this.doc = window.document;
    this.docEl = this.doc.documentElement;

    this.handleSubmit.bind(this);

    this.state = {
      style: {
        top: `${this.props.navState.scrollPosition}px`
      }
    }
  }

  handleSubmit = (event) => {
    if (this.fullscreenToggle) { this.fullscreenToggle = false; this.toggleFullscreen(); return;}
    else {
      if(this.props.navState.playingVideo) this.props.playingVideo(false);
      clearTimeout(this.holdTimer);
      if (this.props.navState.section === "Home") {
        window.scrollTo(0,0);
      }

      this.slightDelay = setTimeout(() => {this.props.toggleContext({options: contextOptions,
                                width: 128,
                                height: 128,
                                position: { x: event.clientX, y: event.clientY},
                                service: 'C_Button',
                                visible: true });
                              }, 50);
    }
  }

  toggleFullscreen = () => {

    var requestFullScreen = this.docEl.requestFullscreen || this.docEl.mozRequestFullScreen || this.docEl.webkitRequestFullScreen || this.docEl.msRequestFullscreen;
    var cancelFullScreen  = this.doc.exitFullscreen || this.doc.mozCancelFullScreen || this.doc.webkitExitFullscreen || this.doc.msExitFullscreen;

    if(!this.doc.fullscreenElement && !this.doc.mozFullScreenElement && !this.doc.webkitFullscreenElement && !this.doc.msFullscreenElement) {
      requestFullScreen.call(this.docEl);
    }
    else {
      cancelFullScreen.call(this.doc);
    }
  }

  handleHold = () => {
    this.holdTimer = setTimeout(() => {
      this.fullscreenToggle = true;
    }, 500)
  }

  render() {
    return (
        <a style={{paddingTop: this.props.topPad,
                   paddingLeft: this.props.leftPad,
                   top: this.state.style.top}}
           className="logoBox"
           onPointerUp  ={(e) => {e.preventDefault(); this.handleSubmit(e);}}
           onPointerDown={(e) => {e.preventDefault(); this.handleHold(e);  }}
           onContextMenu={(e) => {e.preventDefault();                      }}
           href={"#"+this.props.navState.section}>
          <img src={(this.props.color === "white") ? Logo2 : Logo}></img>
        </a>
    )
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
    playingVideo: playing => dispatch(playingVideo(playing)),
    toggleContext: contextMenu => dispatch(toggleContext(contextMenu)),
    updateScrollPosition: scrollPosition => dispatch(updateScrollPosition(scrollPosition)),
    updateNavbar: section => dispatch(updateNavbar(section))
})

const LogoBox = connect(mapStateToProps,mapDispatchToProps)(ConnectedLogoBox)

export default LogoBox
