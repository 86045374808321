import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNavbar, confirmContentLoaded } from '../../actions/simpleAction.js'
import ArchivePost from './ArchivePost.js'

var sightsArrow    = 'https://www.dropbox.com/s/waxj5exszzxhsfz/SightsArrow.png?raw=1';
var soundsArrow    = 'https://www.dropbox.com/s/4i4r3pmvnqa1b9j/SoundsArrow.png?raw=1';
var synthesisArrow = 'https://www.dropbox.com/s/udo5exfnaglplqk/SynthesisArrow.png?raw=1';

class ConnectedArchivePanel extends Component {

  constructor(props){
    super(props);

    this.navToContent = this.navToContent.bind(this);
    this.state = {currentClass: "highlightImage"}

  }

  navToContent = (navLink) => {
    this.props.updateNavbar(navLink);
  }

  render() {

      var borderStyle = ( this.props.navState.windowSize !== undefined
                    && this.props.navState.windowSize.width < 900)
                  ? {borderTop:    `4px solid ${this.props.colors.sectionSubcolor}`,
                     borderBottom: `4px solid ${this.props.colors.sectionSubcolor}`} : {}

      var soloAdjust = (this.props.isSolo) ? " solo" : "";
      return(
        <div style={borderStyle}
          className={"archivePanel"+soloAdjust}>
          {
            this.props.frameInfo.map((item,i) => {
              if (i < 3) return(<ArchivePost isSolo={this.props.isSolo} placement={i} key={i} content={item}/>)
            })
          }
        </div>
      )
    }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    updateNavbar: section => dispatch(updateNavbar(section)),
    confirmContentLoaded: content => dispatch(confirmContentLoaded(content))
})

const ArchivePanel = connect(mapStateToProps, mapDispatchToProps)(ConnectedArchivePanel);

export default ArchivePanel;
