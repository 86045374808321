import React, { createRef} from 'react';
import { connect } from 'react-redux';
import { updateNavbar, updateScrollPosition } from './actions/simpleAction';
import scrollToComponent from 'react-scroll-to-component';

class ConnectedNavBox extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      homeButton: '',
      soundsButton: [],
      sightsButton: [],
      synthesisButton: [],
      marketplaceButton: [],
      scrollPosition: window.scrollY
    }

    window.addEventListener('scroll',this.updateScrollPosition);
    this.updateScrollPosition = this.updateScrollPosition.bind(this);
    this.RenderBusiness = this.RenderBusiness.bind(this);
  }

  updateScrollPosition = (event) => {
    this.setState({ scrollPosition: window.scrollY });
    //this.props.updateScrollPosition(window.scrollY);
  }

  nav = React.createRef();

  navTo = (section) => {
    this.props.updateNavbar(section);
  }

  renderButton = (status, buttonStyle, textStyle, section) => {
    return (
      <a className={status}
       style={{...buttonStyle,
               ...{backgroundImage: `url('${eval("this.props.icons."+section.toLowerCase()+"Img"+(((this.props.color === "white") ? "" : "W"))+".src")}')`}}}
       onClick={() => {this.navTo(section)}} href={"#"+section}>
       {this.renderButtonText(textStyle, section)}
      </a>
    );
  }

  renderButtonText = (style, section) => {
    if (   this.props.navState.windowSize !== undefined
        && this.props.navState.windowSize.width > 900) {
      return(<div style={style}>{section}</div>)
    }
  }

  RenderBusiness = () => {
        let homeButton, soundsButton, sightsButton, synthesisButton, storeButton = '';

        switch (this.props.navState.section) {
          case ('Home'): homeButton = 'active'; break;
          case ('Sounds'): soundsButton = 'active'; break;
          case ('Coalition'): sightsButton = 'active'; break;
          case ('Sights'): sightsButton = 'active'; break;
          case ('Synthesis'): synthesisButton = 'active'; break;
          case ('Store'): storeButton = 'active'; break;
          default: break;
        }

        var defaultButtonStyle = {
                marginTop: "5px",
                right: ( this.props.navState.windowSize !== undefined
                           && this.props.navState.windowSize.width > 900) ? "0px" : "15px",
                color: this.props.color,
                backgroundPosition:  "50%",
                backgroundRepeat:    'no-repeat',
                backgroundSize:      'contain',
                height: (this.props.navState.windowSize !== undefined
                      && this.props.navState.windowSize.width > 900) ? "30px" : "50px"};

        var defaultTextStyle = {color: (this.props.color === "white") ? "black" : "white", position: "relative",top:"40px"};
        return (
          <div>
            {this.renderButton(soundsButton,    defaultButtonStyle, defaultTextStyle, "Sounds")}
            {this.renderButton(sightsButton,    defaultButtonStyle, defaultTextStyle, "Sights")}
            {this.renderButton(synthesisButton, defaultButtonStyle, defaultTextStyle, "Synthesis")}
            {this.renderButton(storeButton,     defaultButtonStyle, defaultTextStyle, "Store")}
          </div>
        );
  }

  render() {

    let navboxClass = (this.state.scrollPosition > 0) ? "navBox sticky" : "navBox";

    //<nav ref={this.nav} className="navBox" style={{top: `${this.props.navState.scrollPosition+100}px`}}>
    return (
       <nav ref={this.nav} className={navboxClass} style={{backgroundColor: this.props.background}}>{this.RenderBusiness()}</nav>

    );
  }
}

const mapStateToProps = state => {
  return { ...state };
};

const mapDispatchToProps = dispatch => ({
    updateScrollPosition: scrollPosition => dispatch(updateScrollPosition(scrollPosition)),
    updateNavbar: section => dispatch(updateNavbar(section))
})

const NavBox = connect(mapStateToProps, mapDispatchToProps)(ConnectedNavBox);
export default NavBox;
