import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { updateNavbar, updateComicFrame, updateComicPage, updateComicPlay } from './actions/simpleAction.js';
import ComicButton from './Components/ComicButton.js';
import NavBox from './NavBox.js';
import SiteHeader from './SiteHeader.js';
import LastPageCursor from './Cursors/LastPageCursor.png'
import LastFrameCursor from './Cursors/LastFrameCursorpng.png'
import NextFrameCursor from './Cursors/NextFrameCursor.png'
import NextPageCursor  from './Cursors/NextPageCursor.png'
import SplashScreen from './Components/Global/SplashScreen.js'

var dbFuncs = require('./Test/dbShorthand.js')

class ConnectedComicReader extends Component {

  // Construction and prep
    constructor(props){
      super(props);

      this.previousPage        = this.previousPage.bind(this);
      this.previousFrame       = this.previousFrame.bind(this);
      this.playPage            = this.playPage.bind(this);
      this.nextFrame           = this.nextFrame.bind(this);
      this.nextPage            = this.nextPage.bind(this);
      this.newestPage          = this.newestPage.bind(this);
      this.handleComicClick    = this.handleComicClick.bind(this);
      this.cancelPlay          = this.cancelPlay.bind(this);
      this.getPageFromDB       = this.getPageFromDB.bind(this);
      this.displayPageAndFrame = this.displayPageAndFrame.bind(this);
      this.animateFrames       = this.animateFrames.bind(this);

      this.xSize    = window.innerWidth;
      this.ySize    = window.innerHeight;

      if (this.imgArray    === undefined) this.imgArray    = new Array();
      if (this.loadedPages === undefined) this.loadedPages = new Array();
      if (this.pageFrames  === undefined) this.pageFrames  = new Array();

      this.playing = false;
      this.buttonResponse = "";

    }

    onPropsWillUpdate = (nextProps) => {

    }

    componentDidMount = async () => {
      try {

        // Grab the global Coalition stat sheet from the database.
        let global_stats = await dbFuncs.process('cataphonic','coalition_global','findOne',{'type': 'stats'});
        this.startPage  = global_stats.startOn;
        this.latestPage = global_stats.latestPage;

        // If there's no page number in the global redux store,
        // Else if the URL has a page number after the Sounds/Coalition route, get the page from the URL.
        // Otherwise just set the page to the first page.
        if ( this.props.navState.pageNumber !== undefined ) { console.log("Wot"); this.currentPage = this.props.navState.pageNumber; }
        else if (window.location.href.split('#/Sights/Coalition/').length === 2) this.currentPage = window.location.href.split('#/Sights/Coalition/')[1];
        else this.currentPage  = this.startPage;

        // If the frame number is in the global redux store, get that.
        // Otherwise just set it to 1.
        this.currentFrame = (this.props.navState.frameNumber === undefined) ? 1 : parseInt(this.props.navState.frameNumber) - 1;
        this.getPageFromDB(this.currentPage, true, (this.currentFrame > 1));

        // Listen for keyup events.
        window.addEventListener("keyup", this.handleComicKeys);

        } catch (error) {
          throw new Error(error);
        }
    }

    componentWillUnmount = () => {
      window.removeEventListener("keyup", this.handleComicKeys)
    }

    componentWillUpdate = (nextProps) => {

    }

    componentDidUpdate = () => {
      if (this.shouldScrollBottom) {
        const node = ReactDOM.findDOMNode(this);
        node.scrollTop = node.scrollHeight;
      }
    }

    handleComicClick(e) {
      //if (e.clientX >= this.xSize / 2) theyis.nextFrame();
      //else this.previousFrame();
  }

    handleComicKeys = (e) => {
        if (e.keyCode === 37) {
          this.previousFrame(e);
          this.props.updateComicFrame(this.currentFrame-1);
        }
        if (e.keyCode === 39) {
          this.nextFrame(e);
          this.props.updateComicFrame(this.currentFrame+1);
        }
    }

    updateWindowSize(e) {
    this.xSize = window.innerWidth;
    this.ySize = window.innerHeight;
  }

    // Grab a page's metadata, along and the previous 2 and next 2 pages' for the sake of a seamless pre-load.
    getPageFromDB = async(pageNum, forward, readFrameFromRedux) => {
      try {
        // Grab the 4 surrounding pages for pre-loading and comfortable reading.
        var pagesToLoad = [parseInt(pageNum)-2,parseInt(pageNum)-1,parseInt(pageNum), parseInt(pageNum)+1, parseInt(pageNum)+2];

        // Asynchronously query each number in pagesToLoad, expecting an array of loaded pages (each a collection of frames).
        let pageDataPromise = Promise.all(pagesToLoad.map(async (item,i) => {
          if (pagesToLoad[i] < 1) return;
          if (this.loadedPages[item] !== undefined) return;

            // Retrieve all frames associated with the queried page number, return an array of frames.
            let pageData = await dbFuncs.process('cataphonic','coalition_frames','findAll',{'page':'P'+pagesToLoad[i]});
            // If the result doesn't exist, early return.
            if (pageData.id === "failed") return;

            // Sort the frames into an explicit load order
            this.pageFrames = pageData.sort((a,b) => {
              return a.frame.split('F')[1] - b.frame.split('F')[1];
            });
            // Prepare a new image array
            this.imgArray = new Array();

            // Map the image array with the queried page's frame URLs
            this.pageFrames.map((pf,j) => {
              this.imgArray[j]     = new Image();
              this.imgArray[j].src = pf.src;
            })

            // Return a page object with an array of frames and pre-loaded images.
            let page = new Object();
              page.number   = pagesToLoad[i];
              page.pageFrames = new Array();
              page.imgArray = new Array();
              page.pageFrames = this.pageFrames;
              page.imgArray = this.imgArray;
            return page;
      }));

      // Wait for all pages to load and assign them.
      let loadedPages = await pageDataPromise;

      // Where each page query had a result, push that result to the loaded pages.
      loadedPages.map((item, i) => {
        if (item !== undefined){
          this.loadedPages[item.number] = new Object();
          this.loadedPages[item.number] = item;
      }
    })

      this.currentPage = pageNum;

      if (!readFrameFromRedux) {
        this.currentFrame = (forward) ? 1 : this.loadedPages[pageNum].pageFrames.length;
      }

      this.timeout = setTimeout(() => {
        this.animateFrames();
        this.props.updateComicPage(this.currentPage);
        this.props.updateComicFrame(this.currentFrame);
      }, 1000)

      this.displayPageAndFrame();

      } catch (error) {
          throw new Error(error);
      }
}

  // Frame and page iterations
    firstPage(e) {
      if (e !== undefined && this.playing) this.cancelPlay();
      this.getPageFromDB(1, true);
    }

    previousPage = async(e) => {
      if (e !== undefined && this.playing) this.cancelPlay();
      if (this.currentPage === 1) return;

        this.currentFrame = -1;
        this.animateFrames();
        this.props.updateComicFrame(this.currentFrame);
        await this.getPageFromDB(parseInt(this.currentPage) - 1, false); // Make sure it's not the newest page.
    }

    previousFrame = async(e) => {
      if (e !== undefined && this.playing) this.cancelPlay();
      if (this.currentFrame === 1) {
        if (this.currentPage === 1) return;
        this.currentFrame = -1;
        this.animateFrames();
        this.props.updateComicFrame(this.currentFrame);
        await this.getPageFromDB(parseInt(this.currentPage) - 1, false); // Make sure it's not the newest page.
      }
      else {
        this.currentFrame = this.currentFrame - 1;
        this.props.updateComicFrame(this.currentFrame);
        this.animateFrames();
        this.displayPageAndFrame();
      }
    }

    playPage = async() => {

      this.playing = !this.playing;

      if (this.playing) {
        this.interval = setInterval(() => {
            this.nextFrame();
            this.props.updateComicFrame(this.currentFrame+1);
        }, 4000);
      }
      else {
        console.log("Stopping play");
        clearInterval(this.interval);
      }
    }

    cancelPlay() {
      this.playing = false;
      clearInterval(this.interval);

      this.props.updateComicPlay(this.playing);
    }

    nextFrame = async(e) => {
      if (e !== undefined && this.playing) this.cancelPlay();
      if (this.currentFrame === this.loadedPages[this.currentPage].pageFrames.length) {
        if (this.currentPage === this.latestPage) return;
        this.currentFrame = -1;
        this.animateFrames();
        this.props.updateComicFrame(this.currentFrame);
        await this.getPageFromDB(parseInt(this.currentPage) + 1, true); // Make sure it's not the newest page.
      }
      else {
        this.currentFrame = this.currentFrame + 1;
        this.props.updateComicFrame(this.currentFrame);
        this.animateFrames();
        this.displayPageAndFrame();
      }
    }
    nextPage = async(e, startAtLastFrame) => {
      if (e !== undefined && this.playing) this.cancelPlay();
      if (this.currentFrame === this.loadedPages[this.currentPage].pageFrames.length) {
        if (this.currentPage === this.latestPage) return;
        this.currentFrame = -1;
        this.animateFrames();
        this.props.updateComicFrame(this.currentFrame);
        await this.getPageFromDB(parseInt(this.currentPage) + 1, (startAtLastFrame === true) ? false : true ); // Make sure it's not the newest page.
      } else {
        this.currentFrame = this.loadedPages[this.currentPage].pageFrames.length;
        this.props.updateComicFrame(this.currentFrame);
        this.animateFrames();
        this.displayPageAndFrame();
      }
      // Check if newest page.
    }

    newestPage = async (e) => {
      this.currentFrame = -1;
      this.animateFrames();
      this.props.updateComicFrame(this.currentFrame);
      await this.getPageFromDB(parseInt(this.latestPage), true); // Make sure it's not the newest page.
    }

  // Info + Debugging
    displayPageAndFrame() {
      console.log("Page #:"+this.currentPage +" | Frame #:"+this.currentFrame+" / "+ this.loadedPages[this.currentPage].pageFrames.length);
    }

    animateFrames = () => {
      this.loadedPages[this.currentPage].pageFrames.map( (item, i) => {
          if (i+1 > this.currentFrame)
            {item.fullClassName = item.class+" "+item.animIn;
             item.delay = item.animInTime;}
          else if (i+1 <= this.currentFrame && item.outFrame != "-" && parseInt(item.outFrame) <= this.currentFrame)
            {item.fullClassName = item.class+" "+item.animOut;
             item.delay = item.animOutTime;}
          else
            {item.fullClassName = item.class;
             item.delay = item.animInTime;}
      });
    }
  render() {

    if (this.props.navState.section !== "Coalition") clearInterval(this.interval);

    if (this.loadedPages.length < 1 ) return (<SplashScreen text={"Loading"} color={{bg: 'black', font: 'white', logo: 'white' }}/>);
    else return(
      <div>
        <div className={"COLControls"}>
          <ComicButton buttonText={"First Page"    } clickEvent={ this.firstPage.bind(this)     }/>
          <ComicButton buttonText={"Previous Page" } clickEvent={ this.previousPage.bind(this)  } style={{backgroundSize: "contain", backgroundImage: `url(${LastPageCursor})`}}/>
          <ComicButton buttonText={"Previous Frame"} clickEvent={ this.previousFrame.bind(this) } style={{backgroundSize: "contain", backgroundImage: `url(${LastFrameCursor})`}}/>
          <ComicButton buttonText={(this.playing) ? "Pause Page" : "Play Page"} clickEvent={ this.playPage.bind(this)      } style={{backgroundSize: "contain", backgroundImage: `url(${NextFrameCursor})`}}/>
          <ComicButton buttonText={"Next Frame"    } clickEvent={ this.nextFrame.bind(this)     } style={{backgroundSize: "contain", backgroundImage: `url(${NextPageCursor})`}}/>
          <ComicButton buttonText={(this.currentFrame === this.pageFrames.length+1) ? "Next Page" : "End of Page" } clickEvent={ this.nextPage.bind(this)      }/>
          <ComicButton buttonText={"Newest Page"   } clickEvent={ this.newestPage.bind(this)    }/>
        </div>

        <div className={"COLViewer"} onKeyPress={(e) => this.handleKeyInputs(e)} >
          <div className={"COLCursor"}>
            <a href={this.indexHash} className={"COLCursor lastPage"}  onClick={(e) => { this.previousPage(e) }}/>
            <a href={this.indexHash} className={"COLCursor lastFrame"} onClick={(e) => { this.previousFrame(e) }}/>
            <a href={this.indexHash} className={"COLCursor nextFrame"} onClick={(e) => { this.nextFrame(e)}}/>
            <a href={this.indexHash} className={"COLCursor nextPage"}  onClick={(e) => { this.nextPage(e, true) }}/>
          </div>
          <div className={"COLPage"}>
          {
              this.loadedPages[this.currentPage].imgArray.map((item, i) => {
                    return (
                            <a href={"#Coalition/"+this.currentPage+"-"+this.currentFrame}>
                                <img key={i}
                                       className={this.loadedPages[this.currentPage].pageFrames[i].fullClassName}
                                       style={{transition: this.loadedPages[this.currentPage].pageFrames[i].delay+"s",
                                              OTransition: this.loadedPages[this.currentPage].pageFrames[i].delay,
                                              WebkitTransition: this.loadedPages[this.currentPage].pageFrames[i].delay+"s",
                                              MozTransition: this.loadedPages[this.currentPage].pageFrames[i].delay+"s",
                                              zIndex: i}}
                                       src={this.loadedPages[this.currentPage].imgArray[i].src}/>
                            </a>
                              )
                })
          }

              {this.loadedPages[this.currentPage].pageFrames.map((item, i) => {
                if (i+1 <= this.currentFrame && item.bonusClass !== undefined){
                    return (
                        <a key={i} className={this.loadedPages[this.currentPage].pageFrames[i].bonusClass}

                           style={{
                                   position: "absolute",
                                   left:   this.loadedPages[this.currentPage].pageFrames[i].bonusPos.split(',')[0],
                                   top:    this.loadedPages[this.currentPage].pageFrames[i].bonusPos.split(',')[1],
                                   width:  this.loadedPages[this.currentPage].pageFrames[i].bonusSize.split(',')[0],
                                   height: this.loadedPages[this.currentPage].pageFrames[i].bonusSize.split(',')[1],
                                   zIndex: 100}}>
                                    <span className={this.loadedPages[this.currentPage].pageFrames[i].bonusClass
                                                     +" "+
                                                     this.loadedPages[this.currentPage].pageFrames[i].bonusHoverClass}
                                          style={{backgroundImage: `url(${this.loadedPages[this.currentPage].pageFrames[i].bonusImage})`,
                                                  width: this.loadedPages[this.currentPage].pageFrames[i].bonusImageSize.split(',')[0],
                                                  height: this.loadedPages[this.currentPage].pageFrames[i].bonusImageSize.split(',')[1],
                                                  }}>
                                                  {this.loadedPages[this.currentPage].pageFrames[i].bonusText}
                                                </span>
                           </a>
                    )}
              })}

          </div>
        </div>

      </div>
    )
  }

}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateNavbar:       section        => dispatch(updateNavbar(section)),
  updateComicPage:    pageNumber     => dispatch(updateComicPage(pageNumber)),
  updateComicFrame:   frameNumber    => dispatch(updateComicFrame(frameNumber)),
  updateComicPlay:    comicIsPlaying => dispatch(updateComicPlay(comicIsPlaying))
});

const ComicReader = connect(mapStateToProps, mapDispatchToProps)(ConnectedComicReader);

export default ComicReader;
