import { UPDATE_NAVBAR,
  SET_PARAMS,
  GET_SPOTIFY_USER,
  GET_SPOTIFY_STREAMING,
  GET_INSTAGRAM_USER,
  SET_SPOTIFY_LOADED,
  SET_TOKENS_RETRIEVED,
  SET_TOP_TRACKS,
  SET_TRACKS_RETRIEVED,
  UPDATE_SCROLL_POSITION,
  UPDATE_WINDOW_SIZE,
  UPDATE_SEARCH_QUERY,
  UPDATE_ARTISTS,
  SET_NOW_PLAYING,
  UPDATE_COMPONENT_COUNT,
  UPDATE_PLAYER_DATA,
  UPDATE_COMIC_PAGE,
  UPDATE_COMIC_FRAME,
  UPDATE_COMIC_PLAY,
  CONFIRM_CONTENT_LOADED,
  PLAYING_VIDEO,
  TOGGLE_CONTEXT
} from "../actions/action-types";

const initialState = {
  contextMenu: {
    options: [],
    height: 0,
    width: 0,
    position: {
      x: 0,
      y: '-200px'
    },
    visible: false,
    type: ''
  },
  playingVideo: false,
  componentCount: 1,
  spotifyLoadedFlag: false,
  tokensRetrievedFlag: false,
  tracksRetrievedFlag: false,
  section: '',
  topTracks: {
    tracks: [{
        artists: [
          {
            name: ''
          }
        ],
        album: {
          images: [{ url: ''}]
        }
  }]},
  params: {
  },
  spotifyUserData: {
    display_name: "",
    followers: {
      total: 0
    },
    external_urls: {
      spotify: ''
    }
  }
};
function rootReducer(state = initialState, action) {
  if (action.type === UPDATE_NAVBAR) {
    return Object.assign({}, state, {
      section: action.payload
    });
  }
  if (action.type === SET_PARAMS) {
    return Object.assign({}, state, {
      params: action.payload
    });
  }
  if (action.type === GET_SPOTIFY_USER) {
    return Object.assign({}, state, {
      spotifyUserData: action.payload
    });
  }
  if (action.type === GET_SPOTIFY_STREAMING) {
    return Object.assign({}, state, {
      spotifyStreamingData: action.payload
    });
  }
  if (action.type === GET_INSTAGRAM_USER) {
    return Object.assign({}, state, {
      instagramUserData: action.payload
    });
  }
  if (action.type === SET_SPOTIFY_LOADED) {
    return Object.assign({}, state, {
      spotifyLoadedFlag: action.payload
    });
  }
  if (action.type === SET_TOKENS_RETRIEVED) {
    return Object.assign({}, state, {
      tokensRetrievedFlag: action.payload
    });
  }
  if (action.type === SET_TOP_TRACKS) {
    return Object.assign({}, state, {
      topTracks: action.payload
    });
  }
  if (action.type === SET_TRACKS_RETRIEVED) {
    return Object.assign({}, state, {
      tracksRetrievedFlag: action.payload
    });
  }
  if (action.type === UPDATE_SCROLL_POSITION) {
    return Object.assign({}, state, {
      scrollPosition: action.payload
    });
  }
  if (action.type === UPDATE_WINDOW_SIZE) {
    return Object.assign({}, state, {
      windowSize: action.payload
    });
  }
  if (action.type === UPDATE_SEARCH_QUERY) {
    return Object.assign({}, state, {
      searchQuery: action.payload
    });
  }
  if (action.type === UPDATE_ARTISTS) {
    return Object.assign({}, state, {
      artists: action.payload
    });
  }
  if (action.type === SET_NOW_PLAYING) {
    return Object.assign({}, state, {
      nowPlaying: action.payload
    });
  }
  if (action.type === UPDATE_COMPONENT_COUNT) {
    return Object.assign({}, state, {
      componentCount: state.componentCount + action.payload
    });
  }
  if (action.type === UPDATE_PLAYER_DATA) {
    return Object.assign({}, state, {
      player: action.payload
    });
  }
  if (action.type === UPDATE_COMIC_FRAME) {
    return Object.assign({}, state, {
      frameNumber: state.componentCount + action.payload
    });
  }
  if (action.type === UPDATE_COMIC_PAGE) {
    return Object.assign({}, state, {
      pageNumber: action.payload
    });
  }
  if (action.type === UPDATE_COMIC_PLAY) {
    return Object.assign({}, state, {
      comicIsPlaying: action.payload
    });
  }
  if (action.type === CONFIRM_CONTENT_LOADED) {
    return Object.assign({}, state, {
      content: action.payload
    });
  }
  if (action.type === PLAYING_VIDEO) {
    return Object.assign({}, state, {
      playingVideo: action.payload
    });
  }
  if (action.type === TOGGLE_CONTEXT) {
    return Object.assign({}, state, {
      contextMenu: action.payload
    });
  }
  return state;
}
export default rootReducer;
