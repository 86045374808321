import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import configureStore from './store';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import index from "./js/index";
import store from "./store/index";
import DDDD from "./DDDD";
import DDDDStats from "./DDDDStats";
import SanityCheck from "./SanityCheck";
import ComicReader from "./ComicReader";
import MgmtConsole from "./Components/Admin/MgmtConsole.js";
import AuthCallback from './Components/Auth/AuthCallback.js';

ReactDOM.render(
  <Router>
   <Provider store={configureStore()}>
    {<Route exact path="/" component={App} />}
    <Route path="/dddd" component={DDDD} />
    <Route path="/dddd-stats/" component={DDDDStats}/>
    <Route path="/sanity" component={SanityCheck}/>
    <Route path="/coalition" component={ComicReader}/>
    <Route path="/super/secret/mgmt/console" component={MgmtConsole}/>
    <Route path="/authCallback" component={AuthCallback}/>
   </Provider>
 </Router>,
 document.getElementById('root')
);

registerServiceWorker();
