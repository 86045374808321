import React, { Component } from 'react';
import HighlightPanel from '../Global/HighlightPanel.js'
import ArchivePanel from '../Global/ArchivePanel.js'
import { connect } from 'react-redux';
import { updateNavbar, confirmContentLoaded } from '../../actions/simpleAction.js'


class ConnectedSectionPanel extends Component {

  constructor(props){
    super(props);
    this.stylePack = null;

    this.frameImages    = new Array();

    this.frameImages[0] = new Array();
    this.frameImages[1] = new Array();
    this.frameImages[2] = new Array();
    this.frameImages[3] = new Array();

    this.renderHidden = this.renderHidden.bind(this);
    this.getSectionColor = this.getSectionColor.bind(this);
  }

  navTo = (section) => {
    this.props.updateNavbar(section);
  }

  componentDidMount = () => {
    if (window.innerWidth > 900) {

    }
  }

  renderHidden = () => {


    return(
      <div style={{
                    left: (this.props.orientation === 'ltr') ? '-100%' : '100%',
                    direction: this.props.orientation}}
           className={this.props.currentClass}>
        <div className={"sectionHeader"}>
          <div className={"sectionHeaderText"}></div>
        </div>
        <div className={"highlightFrame"}></div>
      </div>);
  }

  getSectionColor = () => {
      if (this.props.highlightContent === undefined) return "white"
      else switch (this.props.highlightContent.Section) {
              case ("Sounds"):    {
                this.stylePack = {
                  sectionHeaderColor : "#757575",
                  sectionColor       : "#b3b3b3",
                  sectionSubcolor    : "#d9d9d9",
                  fontColor          : "black",
                  fontSubcolor       : "black"
                }
                break;
              }
              case ("Sights"):    {
                this.stylePack = {
                  sectionHeaderColor : "#66818a",
                  sectionColor       : "#91b4bf",
                  sectionSubcolor    : "#add8e6",
                  fontColor          : "black",
                  fontSubcolor       : "black"
                }
                break;
              }
              case ("Synthesis"): {
                this.stylePack = {
                  sectionHeaderColor : "#333333",
                  sectionColor       : "#5c5c5c",
                  sectionSubcolor    : "#858585",
                  fontColor          : "white",
                  fontSubcolor       : "white"
                }
                break;
              }
              default: break;
            }
  }

  render() {
      if (this.props.currentClass === "sectionPanel hidden") return(this.renderHidden());
      else {
        var soloAdjust = (this.props.isSolo) ? " solo" : "";
        if (this.stylePack === null) this.getSectionColor();
        return(
        <div style={{ boxShadow: `0px 1px 10px ${this.stylePack.sectionColor}`, direction: this.props.orientation}} className={this.props.currentClass+soloAdjust}>
          <div style={{backgroundColor: this.stylePack.sectionHeaderColor}} className={"sectionHeader"+soloAdjust}>
            <a onClick={() => this.navTo((this.props.isSolo) ? "Home" : this.props.highlightContent.Section)}
               href={(this.props.isSolo) ? "#Home" : "#"+this.props.highlightContent.Section}
               className={"sectionHeaderText"+soloAdjust}
               style={{textDecoration: "none"}}>
              <div style={{color: "white", fontFamily: this.props.highlightContent.fontFamily}}>{this.props.highlightContent.Section}</div>
            </a>
          </div>
          <HighlightPanel isSolo={this.props.isSolo} style={this.stylePack} order={this.props.orientation} frameInfo={this.props.highlightContent}/>
          <ArchivePanel   isSolo={this.props.isSolo} colors={this.stylePack} frameInfo={this.props.oldContent}/>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    updateNavbar: section => dispatch(updateNavbar(section)),
    confirmContentLoaded: content => dispatch(confirmContentLoaded(content))
})

const SectionPanel = connect(mapStateToProps, mapDispatchToProps)(ConnectedSectionPanel);

export default SectionPanel;
