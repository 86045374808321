import React, { Component } from 'react';
import { connect } from 'react-redux'
//import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import SpotifyModule from './modules/SpotifyModule'

class ConnectedContentBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedPanel: '',
      loaded: false,
      loadType: 'home'
    }
  }

  render() {
    if (this.props.navState.artists) {
      return (<div className="contentBox">{this.props.navState.artists.map((item, i) => { return(<div key={i}><SpotifyModule username={item.username} artistName={item.artist_name} artist_id={item.spotify_id} artistStats={item.artistStats} loadType={this.state.loadType}/></div>)})}</div>);
    }
    else {
      return <div></div>
    }
  }
}

const mapStateToProps = state => ({
 ...state
})

const ContentBox = connect(mapStateToProps)(ConnectedContentBox);
export default ContentBox;
