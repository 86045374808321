import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, updateArtists } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';
import AlbumBox from '../Sounds/AlbumBox.js';

const dbFuncs = require('../../Test/dbShorthand.js');

const sources = ['Home','Sounds','Sights','Synthesis','Store'];

class ConnectedArtistMenu extends React.Component {

    constructor (props) {
      super(props);
      this.state = ({});
    }

    renderMenu = () => {
      if (this.props.navState.section.split('/').length > 1) {
          switch (this.props.navState.section.split('/')[1].toLowerCase()) {
            case ("music"): { return(<React.Fragment>{this.renderMusic()}</React.Fragment>); break; }
            case ("merch"): { return(<React.Fragment>{this.renderMerch()}</React.Fragment>); break; }
            case ("about"):   { return(<React.Fragment>{this.renderAbout()}</React.Fragment>); break; }
            //case ("events"):{ return(<React.Fragment>{this.renderEvents()}</React.Fragment>); break;}
            default: break;
          }
        } else { return(<React.Fragment>{this.renderUnselected()}</React.Fragment>); }
    }

    renderMusic      = () => {
        return(<React.Fragment>
          {
            this.props.navState.artists.albums.map((item, i) => {
              return(<React.Fragment key={i}>
                      <AlbumBox streamingIcons={this.props.streamingIcons} number={i} album={item}/>
                     </React.Fragment>);
            })
          }
        </React.Fragment>)
    }
    renderMerch      = () => {
      return(
      <div>
        Merch
      </div>);
    }
    renderAbout      = () => {
      let timeSortedPosts = this.props.navState.artists.social.find((s) => {
        return(s.service === 'instagram');
      }).posts;

      let videos = timeSortedPosts.filter((n) => { return (n.videos !== undefined)});
      let images = timeSortedPosts.filter((n) => { return (n.videos === undefined)});

      let postPairs = videos.map(function(v,i){ return [v, images[i]]; });

      return(<div className={"artistMenu"}
                  style={{
                          opacity: 0.8,
                          position: 'relative',
                          width: "100%",
                          height: '100%',
                          maxHeight: '100%',
                          display: 'flex',
                          flexFlow: 'row wrap',
                          backgroundColor: '#ffffe6'
                          }}>
            <div style={{position: "absolute", width: '100%', height: '200px', color: 'yellow', opacity: 0.5}}/>
            <span className={'artistAboutBio'}>{this.props.navState.artists.profile.bio}</span>
          {
            postPairs.map((item, i) => {
              if (i > 2) return;
              return(<div key={i} style={{  height: `${(i === 0) ? 210 : 140 }px`,direction: (i%2 === 0) ? 'ltr': 'rtl'}}>{this.renderAboutSlide(item,i)}</div>)
          })
        }
      </div>);
    }

    renderAboutSlide = (item, i) => {
        return(item.map((post, j) => {
         if (post !== undefined && post.videos === undefined) {
            return(
                    <img key={j} src={post.images.standard_resolution.url} style={{ width: `${33}%`,
                                                                            height: `${(i === 0) ? 210 : 140}px`,
                                                                            objectFit: 'cover'}}/>)}
         else if (post !== undefined) return (
           <video key={j} src={post.videos.low_bandwidth.url}
                  style={{ width: `${67}%`,
                           height: `${(i === 0) ? 210 : 140}px`,
                           objectFit: 'cover'}}
                  muted={true}
                  controls={false}
                  loop autoPlay/>)
        else if (post === undefined) return(<div key={i} style={{minWidth: '33%'}}></div>)
       }));
    }

    renderUnselected = () => {

      return(<div className={"artistMenu"}
                  style={{
                          position: 'relative',
                          width: "100%",
                          height: '30%',
                          maxHeight: '30%',
                          display: 'flex',
                          flexFlow: 'row wrap',
                          opacity: 0.8}}>
        {this.renderMusic()}
      </div>);
    }

    render() {
      const artistHash = "#"+this.props.navState.artists.username.toUpperCase();

      return(
      <div style={{color: (this.props.navState.artists.siteStyle.theme === "dark") ? "white" : "black"}} className={"artistMenuContainer"}>
        <div className={"artistMenuNav"}>
          <a style={{borderLeft: '0px'}} href={artistHash+"/music"} className={"artistMenuNavButton"}>Music</a>
          <a href={artistHash+"/merch"} className={"artistMenuNavButton"}>Merch</a>
          <a href={artistHash+"/events"} className={"artistMenuNavButton"}>Events</a>
          <a href={artistHash+"/about"} className={"artistMenuNavButton"}>About </a>
        </div>
        {this.renderMenu()}
      </div>)
    }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateArtists: artistInfo => dispatch(updateArtists(artistInfo))
})

const ArtistMenu = connect(mapStateToProps,mapDispatchToProps)(ConnectedArtistMenu)

export default ArtistMenu;
