import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNavbar, updateWindowSize, confirmContentLoaded } from '../../actions/simpleAction.js'

var sightsArrow    = 'https://www.dropbox.com/s/waxj5exszzxhsfz/SightsArrow.png?raw=1';
var soundsArrow    = 'https://www.dropbox.com/s/4i4r3pmvnqa1b9j/SoundsArrow.png?raw=1';
var synthesisArrow = 'https://www.dropbox.com/s/udo5exfnaglplqk/SynthesisArrow.png?raw=1';

class ConnectedHighlightPanel extends Component {

  constructor(props){
    super(props);

    this.navToContent = this.navToContent.bind(this);
    this.state = {currentClass: "highlightImage"}

    switch (this.props.frameInfo.Section) {
      case ("Sounds"):    this.sectionArrow = sightsArrow;    break;
      case ("Sights"):    this.sectionArrow = soundsArrow;    break;
      case ("Synthesis"): this.sectionArrow = synthesisArrow; break;
    }
  }

  navToContent = (navLink) => {
    this.props.updateNavbar(navLink);
  }

  render() {

      var arrowPos = new Object();

      if (this.props.navState.windowSize.width < 900) {
        arrowPos = {
          transform: 'rotate(90deg)',
          position: "relative",
          width: "50px",
          height: "97vw",
          top: "25px",
          transformOrigin: "top",
          left: "50%"
        }
      } else {
        arrowPos = {
          transform: (this.props.order === 'ltr') ? 'rotate(0deg)' : 'rotate(180deg)' ,
          position: "absolute",
          width: "100px",
          height: "99.5%",
          top: "0%",
          left: (this.props.order === 'ltr')  ? "100%" : "0%",
          right: (this.props.order === 'ltr') ? "0%"    : "100%"}
      }

      var soloAdjust = (this.props.isSolo) ? " solo" : "";

      return(
        <div className={"highlightFrame" + soloAdjust}>
          <div className={"highlightPost" + soloAdjust}>
            <div style={{fontFamily: (this.props.frameInfo.fontFamily === undefined) ? "" :this.props.frameInfo.fontFamily, backgroundColor: this.props.style.sectionColor, color: this.props.style.fontColor}} className={"highlightTitle"+soloAdjust}>   {this.props.frameInfo.Title}   </div>
            <div style={{fontFamily: (this.props.frameInfo.fontFamily === undefined) ? "" :this.props.frameInfo.fontFamily, backgroundColor: this.props.style.sectionSubcolor, color: this.props.style.fontSubcolor}} className={"highlightSubtitle"+soloAdjust}>{this.props.frameInfo.Subtitle}</div>
            <div style={{fontFamily: (this.props.frameInfo.fontFamily === undefined) ? "" :this.props.frameInfo.fontFamily, color: "black"}} className={"highlightBody"+soloAdjust}>    {this.props.frameInfo.Body}    </div>
            <img src={this.sectionArrow} style={arrowPos}/>
          </div>
            {

                this.props.frameInfo.Images.map((item, i) => {

                  var baseStyle = (this.props.navState.windowSize.width > 900)
                                ? {objectPosition: this.props.frameInfo.imageOffset,
                                   left: this.props.frameInfo.fromLeft,
                                   zIndex: (this.props.frameInfo.clickableImage === i) ? 20 : i,
                                   width: this.props.frameInfo.imageWidth,
                                   objectFit: this.props.frameInfo.imageFit}
                                : {objectPosition: this.props.frameInfo.imageOffset,
                                   zIndex: (this.props.frameInfo.clickableImage === i) ? 20 : i,
                                   width: "100%",
                                   height: (this.props.frameInfo.Content_Nav === this.props.navState.section) ? "100%" : "205px",
                                   top: "130px",
                                   left: 0,
                                   objectFit: this.props.frameInfo.imageFit}

                  var combinedStyle = (this.props.frameInfo.ImageStyle !== undefined
                                       && this.props.frameInfo.ImageStyle[i] !== undefined) ? {...baseStyle, ...this.props.frameInfo.ImageStyle[i]} : baseStyle;

                  if (i === this.props.frameInfo.clickableImage) return(
                         <a key={i} href={"#"+this.props.frameInfo.contentNav}>
                          <img src={(window.innerWidth > 900) ? item.desktop : item.mobile }
                             className={(  i === this.props.frameInfo.staticImage
                                        && !this.props.frameInfo.animateAll ) ? "highlightImage"+soloAdjust : this.state.currentClass+soloAdjust}
                             style={combinedStyle}
                             onClick={() => {this.navToContent(this.props.frameInfo.contentNav);} }
                             href={"#"+this.props.frameInfo.contentNav}
                             onMouseEnter={() =>  { this.setState({currentClass: "highlightImage hovered"+soloAdjust}); }}
                             onMouseLeave ={() => { this.setState({currentClass: "highlightImage"+soloAdjust});         }}/>
                          </a>
                         );
                  else return(
                         <img src={(window.innerWidth > 900) ? item.desktop : item.mobile}
                             className={(i === this.props.frameInfo.staticImage) ? "highlightImage" : this.state.currentClass}
                             style={combinedStyle}
                             key={i}/>
                         );
                })
            }
          </div>
      )
    }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    updateNavbar: section => dispatch(updateNavbar(section)),
    confirmContentLoaded: content => dispatch(confirmContentLoaded(content))
})

const HighlightPanel = connect(mapStateToProps, mapDispatchToProps)(ConnectedHighlightPanel);

export default HighlightPanel;
