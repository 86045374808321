import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { updatePlayerData } from './actions/simpleAction.js';
import Ball from './icons/ball.png';
import Head from './icons/HEAD_0.png';
import Body from './icons/BODY_0.png';
import RightArm from './icons/RIGHTARM_0.png';
import LeftArm from './icons/LEFTARM_0.png';
import RightLeg from './icons/RIGHTLEG_0.png';
import LeftLeg from './icons/LEFTLEG_0.png';

class ConnectedDDDDAvatarPlayer extends Component {

  constructor(props) {
    super(props);
        this.headColor = {
          r: Math.round(this.props.headColor.split(',')[0].split('(').pop() * 255),
          g: Math.round(this.props.headColor.split(',')[1] * 255),
          b: Math.round(this.props.headColor.split(',')[2] * 255),
          a: Math.round(this.props.headColor.split(',')[3].split(')')[0] * 255)
         }
        this.bodyColor = {
          r: Math.round(this.props.bodyColor.split(',')[0].split('(').pop() * 255),
          g: Math.round(this.props.bodyColor.split(',')[1] * 255),
          b: Math.round(this.props.bodyColor.split(',')[2] * 255),
          a: Math.round(this.props.bodyColor.split(',')[3].split(')')[0] * 255)
        }
        this.armsColor = {
          r: Math.round(this.props.armsColor.split(',')[0].split('(').pop() * 255),
          g: Math.round(this.props.armsColor.split(',')[1] * 255),
          b: Math.round(this.props.armsColor.split(',')[2] * 255),
          a: Math.round(this.props.armsColor.split(',')[3].split(')')[0] * 255)
        }
        this.legsColor = {
          r: Math.round(this.props.legsColor.split(',')[0].split('(').pop() * 255),
          g: Math.round(this.props.legsColor.split(',')[1] * 255),
          b: Math.round(this.props.legsColor.split(',')[2] * 255),
          a: Math.round(this.props.legsColor.split(',')[3].split(')')[0] * 255)
        }

        this.headImg = null;
        this.bodyImg = null;
        this.lArmImg = null;
        this.rArmImg = null;
        this.lLegImg = null;
        this.rLegImg = null

        this.headCanvas = null;
        this.bodyCanvas = null;
        this.lArmCanvas = null;
        this.rArmCanvas = null;
        this.lLegCanvas = null;
        this.rLegCanvas = null;

        this.updateColor = this.updateColor.bind(this);

        // Images
        this.setHeadImg    = element => {
          this.headImg = element;
        };
        this.setBodyImg    = element => {
          this.bodyImg = element;
        };
        this.setLArmImg    = element => {
          this.lArmImg = element;
        };
        this.setRArmImg    = element => {
          this.rArmImg = element;
        };
        this.setLLegImg    = element => {
          this.lLegImg = element;
        };
        this.setRLegImg    = element => {
          this.rLegImg = element;
        };

        // Canvases
        this.setHeadCanvas = element => {
          this.headCanvas = element;
        };
        this.setBodyCanvas = element => {
          this.bodyCanvas = element;
        };
        this.setLArmCanvas = element => {
          this.lArmCanvas = element;
        };
        this.setRArmCanvas = element => {
          this.rArmCanvas = element;
        };
        this.setLLegCanvas = element => {
          this.lLegCanvas = element;
        };
        this.setRLegCanvas = element => {
          this.rLegCanvas = element;
        };

        this.changeImage = () => {
          if (this.headImg != null
            && this.headCanvas != null) this.updateColor(this.headImg, this.headCanvas, this.headColor);
          if (this.bodyImg != null
            && this.bodyCanvas != null) this.updateColor(this.bodyImg, this.bodyCanvas, this.bodyColor);
          if (this.lArmImg != null
            && this.lArmCanvas != null) this.updateColor(this.lArmImg, this.lArmCanvas, this.armsColor);
          if (this.rArmImg != null
            && this.rArmCanvas != null) this.updateColor(this.rArmImg, this.rArmCanvas, this.armsColor);
          if (this.lLegImg != null
            && this.lLegCanvas != null) this.updateColor(this.lLegImg, this.lLegCanvas, this.legsColor);
          if (this.rLegImg != null
            && this.rLegCanvas != null) this.updateColor(this.rLegImg, this.rLegCanvas, this.legsColor);
        }

  }
  componentDidMount = () => {
     this.changeImage();
  }
  componentDidUpdate = () => {
    this.changeImage();
  }

  updateColor = (img, cvs, col) => {

      var canvas = ReactDOM.findDOMNode(cvs);
      var image  = ReactDOM.findDOMNode(img);
      var ctx    = canvas.getContext("2d");
      ctx.clearRect(0,0,128,128);
      canvas.height = canvas.width = 128;
      ctx.drawImage(image,0,0);

      var imgd = ctx.getImageData(0, 0, 128, 128),
          pix = imgd.data;

      for (var i = 0, n = pix.length; i <n; i += 4) {
          var r = pix[i],
                  g = pix[i+1],
                  b = pix[i+2];

              if(r == 255 && g == 255 && b == 255){
                  // Change the white to the new color.
                  pix[i]   = col.r;
                  pix[i+1] = col.g;
                  pix[i+2] = col.b;
                  pix[i+3] = col.a;
              }
      }

      ctx.putImageData(imgd, 0, 0);
    }

  render () {
    return(<div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{position: "relative"}}>
          <img style={{position: "absolute", top: 0, left: '75px', zIndex: 10}} id="headImg" src={Head}     ref={this.setHeadImg}/>
          <img style={{position: "absolute", top: 0, left: '75px', zIndex: 6 }} id="bodyImg" src={Body}     ref={this.setBodyImg}/>
          <img style={{position: "absolute", top: 0, left: '75px', zIndex: 5 }} id="lArmImg" src={LeftArm}  ref={this.setLArmImg}/>
          <img style={{position: "absolute", top: 0, left: '75px', zIndex: 7 }} id="rArmImg" src={RightArm} ref={this.setRArmImg}/>
          <img style={{position: "absolute", top: 0, left: '75px', zIndex: 5}}  id="lLegImg" src={LeftLeg}   ref={this.setLLegImg}/>
          <img style={{position: "absolute", top: 0, left: '75px', zIndex: 7}}  id="rLegImg" src={RightLeg}  ref={this.setRLegImg}/>
        </div>
        <div style={{position: "relative"}}>
          <canvas style={{position: "absolute", top: 0, left: '75px', zIndex: 10}} id="headCanvas" ref={this.setHeadCanvas}></canvas>
          <canvas style={{position: "absolute", top: 0, left: '75px', zIndex: 6}} id="bodyCanvas"  ref={this.setBodyCanvas}></canvas>
          <canvas style={{position: "absolute", top: 0, left: '75px', zIndex: 5}} id="lArmCanvas"  ref={this.setLArmCanvas}></canvas>
          <canvas style={{position: "absolute", top: 0, left: '75px', zIndex: 7}} id="rArmCanvas"  ref={this.setRArmCanvas}></canvas>
          <canvas style={{position: "absolute", top: 0, left: '75px', zIndex: 5}} id="lLegCanvas"  ref={this.setLLegCanvas}></canvas>
          <canvas style={{position: "absolute", top: 0, left: '75px', zIndex: 7}}  id="rLegCanvas"  ref={this.setRLegCanvas}></canvas>
          </div>
      </div>);
  }



}

const mapDispatchToProps = dispatch => ({
  playerData: player => dispatch(updatePlayerData(player))
});

const DDDDAvatarPlayer = connect(null, mapDispatchToProps)(ConnectedDDDDAvatarPlayer);

export default DDDDAvatarPlayer;
