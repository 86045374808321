import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, updateArtists, setNowPlaying } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';
const dbFuncs = require('../../Test/dbShorthand.js');

const exclude = ['Home','Sounds','Sights','Synthesis','Store'];

var pauseButtonURL = 'https://www.dropbox.com/s/snawxvwaw69gb1l/PAUSE.png?raw=1';
var playButtonURL = 'https://www.dropbox.com/s/xrfj9w5ixrhrkd3/PLAY.png?raw=1';

function getTime(time) {
  if(!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
  }
}

const Filler = (props) => {
  return <div className="filler" style={{width: `${props.percentage * 100}%`}}/>
}

class ConnectedMusicPlayer extends React.Component {

    constructor (props) {
      super(props);

      this.state = {currentTime: 0, duration: 0, hidden: true, playing: false}

      this.playButton       = new Image();
      this.pauseButton      = new Image();
      this.playButton.src = playButtonURL;
      this.pauseButton.src = pauseButtonURL;
    }

    componentDidMount = () => {
      this.player.addEventListener("timeupdate", (e) => {
        this.setState({
          currentTime: e.target.currentTime,
          duration: e.target.duration
        })
      });
    }
    componentWillUnmount = () => {
      window.removeEventListener("timeupdate",() => {});
    }

    componentDidUpdate(prevProps, prevState) {
      let playingNow  = (this.props.navState.nowPlaying === undefined) ? "" : this.props.navState.nowPlaying.name;
      let playingLast = (prevProps.navState.nowPlaying === undefined) ? "" : prevProps.navState.nowPlaying.name;

      if( playingNow !== playingLast ) {
        let song = this.props.navState.nowPlaying.dropbox;
        if (song) {
          this.setState({hidden: false});
          clearTimeout(this.hideTimer);
          this.player.src = song;
          this.player.play();
          this.setState({playing: true})
          this.hideTimer = setTimeout(() => {this.setState({hidden: true})},5000)
        }
      } else if (this.props.navState.nowPlaying
              && prevProps.navState.nowPlaying
              && this.props.navState.nowPlaying.playing !== prevProps.navState.nowPlaying.playing) {
                if (this.props.navState.nowPlaying.playing) {this.player.play(); this.setState({playing: true})}
                else {this.player.pause();
                  this.setState({playing: false})};
      }
    }

    renderSongImage = () => {
      if (this.props.navState.nowPlaying !== undefined) return(
        <img src={this.props.navState.nowPlaying.image}
             style={{opacity: (this.state.hidden) ? 0.3 : 0.3 ,position: "absolute", left: 0, objectFit: "cover", width: "100%", height: "100%"}}/>
      )
    }
    renderProgressBar = () => {

      if (this.props.navState.nowPlaying !== undefined) return(
        <div className="progressBar">
          <Filler percentage={this.state.currentTime/this.state.duration}/>
        </div>);
    }
    renderSongInfo = () => {
      const currentTime = getTime(this.state.currentTime);
      const duration = getTime(this.state.duration);


      if (this.props.navState.nowPlaying !== undefined) return(<div className={"musicPlayerInfo"}>
                <div className={"musicPlayerTitle"}>
                  <span style={{color: this.props.navState.nowPlaying.favoriteColor}}>{this.props.navState.nowPlaying.artist}</span>
                  <span>{" - "+this.props.navState.nowPlaying.name}</span>
                  <span style={{padding: "3px 20px 0px 20px"}} className={"musicPlayerTimer"}>{currentTime} / {duration}</span></div>

                {this.renderProgressBar()}
              </div>);
    }
    render() {

            return(
            <div onClick={() => {
                this.hideTimer = setTimeout(() => {this.setState({hidden: true})},5000);
                this.setState({hidden: false})
              }}
              className={(!this.state.hidden)
                         ? ((!exclude.includes(this.props.navState.section.split('/')[0])) ? "musicPlayer" : "musicPlayer bottom")
                         : ((this.props.navState.nowPlaying === undefined)
                           ? "musicPlayer empty" : ((!exclude.includes(this.props.navState.section))
                                                    ? "musicPlayer hidden" : "musicPlayer bottom"))}>
              {this.renderSongImage()}
              {this.renderSongInfo()}
              <audio ref={ref => this.player = ref}/>
            </div>
            )
      }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateArtists: artistInfo => dispatch(updateArtists(artistInfo)),
  setNowPlaying: songPlaying => dispatch(setNowPlaying(songPlaying))
})

const MusicPlayer = connect(mapStateToProps,mapDispatchToProps)(ConnectedMusicPlayer)

export default MusicPlayer;
