import React, { Component } from 'react';
//import SpotifyLogo from '../icons/spotify.png';
import InfoGraph from '../InfoGraph.js'
import SpotifyBG from '../icons/spotifyBG.jpg'
import { connect } from 'react-redux';
import { getSpotifyUserData,
         getSpotifyStreamingData,
         setSpotifyLoaded,
         setTopTracks,
         setTracksRetrieved,
         setNowPlaying,
         updateComponentCount } from '../actions/simpleAction'
import FuzzySearch from 'fuzzy-search'

const dbFuncs = require('../Test/dbShorthand.js')



function pad(n, width, z) {
 z = z || '0';
 n = n + '';
 return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function getDate(){
  let today = new Date();
  let date  = today.getFullYear()+'-'+pad(today.getMonth()+1,2)+'-'+pad(today.getDate(),2);

  return date;
}

const aggregateStyle = {width: '50%', textAlign: 'center',backgroundColor: 'lightgray', fontSize: '14px', color: 'black'}

class ConnectedSpotifyModule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      loaded: false
      }
    this.handleClick = this.handleClick.bind(this);
    this.setNowPlaying = this.setNowPlaying.bind(this);
  }

  componentWillReceiveProps = (nextProps) => {

    if (this.state.loaded && nextProps.navState.searchQuery !== this.props.navState.searchQuery) {

      let results = [];
      let visibility;

      const searcher = new FuzzySearch(this.props.artistName,['name'], {caseSensitive: false})
      results = searcher.search(nextProps.navState.searchQuery);

      if(results[0]) {
        if (results[0].name == this.props.artistName) visibility = true;
      } else {
        if (nextProps.navState.searchQuery === '' || nextProps.navState.searchQuery === 'Search our database') { visibility = true; }
        else { visibility = false;}
      }
      this.setState({visible: visibility});
    }
}

  componentDidMount = () => {


    /*
      dbFuncs.process('cataphonic','tracks','query', {  date_updated: { $gte: getDate() } , 'artists.id': this.props.artist_id  })
          .then ( d => {
            if (d.length > 0) {
              console.log('Got today\'s tracks from DB');
              this.setTopTracks(d);
              this.getAggregateStats();
            } else {
              console.log("Today's data not in DB, grabbing from API");
              this.getTracksFromSpotify()
              .then (f => {console.log("Data retrieved from Spotify")})
              .catch(e => {console.log("Spotify pull died")});
            }})
          .catch( e => console.log("DB Call Failed"))
          */
  }

  getTracksFromSpotify = () => {
    return new Promise((resolve, reject) => {
      fetch("https://cataphonic.studio:8888/spotifyTracks?artist_id="+this.props.artist_id+"&access_token="+this.props.navState.params.sp_access_token)
                  .then ( result => { return result.json(); })
                  .then ( f => {resolve(this.setTopTracks(f.tracks)); } )
                  .catch( e => {reject(console.log("Everything failed"))} )
    })
  }

  getAggregateStats = () => {

      // Grab aggregate data from the most recent date ( which would pull as item '0' in the array)
      let tracksToday     = this.props.artistStats[0].tracks.length
      let totalPopularity = this.props.artistStats[0].totalPopularity;
      let avgPopularity   = totalPopularity / tracksToday;

      this.setState({
                      loaded: true,
                      numTracks: tracksToday,
                      totalPopularity: totalPopularity,
                      averagePopularity: avgPopularity,
                      lastUpdated: this.props.artistStats[0]._id
                                                    })
  }

  getSpotifyUserData = (spotifyUserData) => {
      this.props.getSpotifyUserData(spotifyUserData);
  }

  async setLoaded (val) {
    this.setState( { loaded: val })
  }
  setNowPlaying = (track) => {
   //this.props.setNowPlaying(this.state.tracks[0]);
  }
  setTracksRetrieved = (flag) => {
      this.props.setTracksRetrieved(flag);
  }
  setTopTracks = (topTracks) => {
        this.setState( {
          tracks: topTracks
        });

        setTimeout(() => {this.setLoaded(true);/*console.log("Modules loaded")*/},0);

  }
  setSpotifyLoaded = (flag) => {
      this.props.setSpotifyLoaded(flag);

  }

  handleClick = () => {
    if(this.props.navState.params && this.props.navState.params.sp_access_token === "")
      window.location.replace("https://cataphonic.studio:8888/spotifyLogin");
  }
  renderContainer = () => {

    if (this.state.loaded &&
        this.props.artist_id &&
        this.props.artistStats &&
        this.props.artistStats.length > 0 &&
        this.state.visible) {
        return (
          <div onClick={this.setNowPlaying} className="spotifyBox">
          <div className="image" style={{backgroundPosition: 'left',backgroundImage: `url(${SpotifyBG})`, backgroundSize: '80px 45px', color: 'white', display: 'flex',width: '100%', backgroundColor: '#101010'}}>
            <div style={{marginTop: 'auto', marginBottom: 'auto', marginRight: 'auto', marginLeft: '90px', fontSize: '1.6em', width: '40%'}}>{this.props.artistName}
            {/*<br/>Followers: {this.props.navState.artists[this.props.artist_id]}*/}
            </div>
            <div style={{textAlign: 'right', display: 'flex', backgroundColor: '#3D3D3D', border: '#4C4C4C 2px solid', flexDirection: 'column', width: '40%', padding: '5px'}}>
              <div>{'Popularity Today: '+Math.round(this.state.totalPopularity)}</div>
              <div>{'Avg: '+Math.round(this.state.averagePopularity)}</div>
              <div>{"Top "+this.state.numTracks+' tracks'}</div>
              <div>{'Last Updated: '+this.state.lastUpdated}</div>

            </div>
          </div>
            <InfoGraph chartID={this.props.username} artist_id={this.props.artist_id} artistStats={this.props.artistStats}/>
          </div>
        )
      } else {
        if (this.props.artistStats[0] && this.props.artistStats[0].tracks) this.getAggregateStats();
      }
  }

  render() {

        return (
              <div onClick={this.handleClick}>
                {this.renderContainer()}
              </div>
              );

  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
    updateComponentCount: componentCount => dispatch(updateComponentCount(componentCount)),
    setNowPlaying: nowPlaying => dispatch(setNowPlaying(nowPlaying)),
    setSpotifyLoaded: spotifyLoadedFlag => dispatch(setSpotifyLoaded(spotifyLoadedFlag)),
    getSpotifyUserData:  spotifyUserData => dispatch(getSpotifyUserData(spotifyUserData)),
    getSpotifyStreamingData: spotifyStreamingData => dispatch (getSpotifyStreamingData(spotifyStreamingData)),
    setTopTracks: topTracks => dispatch(setTopTracks(topTracks)),
    setTracksRetrieved: tracksRetrievedFlag => dispatch(setTracksRetrieved(tracksRetrievedFlag))
})

const SpotifyModule = connect(mapStateToProps, mapDispatchToProps)(ConnectedSpotifyModule);

export default SpotifyModule;
