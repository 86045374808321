import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogoBox from './LogoBox.js';
import NavBox  from './NavBox.js';
import scrollToComponent from 'react-scroll-to-component';
import { updateNavbar } from './actions/simpleAction.js'


class ConnectedSanityCheck extends Component {

  constructor(props){
    super(props);

    const url = window.location.href;
    const lastPart= url.split("/").pop();

    this.scrolledTo = false;

    this.url = url;
    this.lastPart = lastPart;
    this.hashNav  = lastPart.split("#").pop();

    this.statsSection = React.createRef();
    this.playSection  = React.createRef();

  }


  render() {

    if (this.hashNav != "" && !this.scrolledTo) {
      if (this.hashNav == "Stats") scrollToRef(this.statsSection);
      if (this.hashNav == "Play")  scrollToRef(this.playSection);
      this.scrolledTo = true;
    }

    return(
      <div>
        <LogoBox color={"white"} shadow={"n"} />
        <NavBox color={"white"} background={"transparent"} mode={"DDDD"}/>
        <iframe ref={this.playSection} src="https://i.simmer.io/@Xihro/~2d6c62e3-559e-9395-0180-f30c02f01a7e" style={{ width: '100vw', height: '100vh'}}></iframe>
        {/*<DDDDStats ref={this.statsSection}/>
         */}</div>
    )
  }

}

const scrollToRef = (ref) => {
  console.log(ref);
  window.scrollTo(0, ref.current);
}
const mapDispatchToProps = dispatch => ({
  updateNavbar: section => dispatch(updateNavbar(section))
});

const SanityCheck = connect(null, mapDispatchToProps)(ConnectedSanityCheck);

export default SanityCheck;
