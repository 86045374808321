import React, { Component } from 'react';
import SiteHeader from '../../SiteHeader.js'
import SplashScreen from '../Global/SplashScreen.js'
import { connect } from 'react-redux';


var ObjectID = require('mongodb').ObjectID;
var dbFuncs = require('../../Test/dbShorthand.js')
//import { updateNavbar } from '../../actions/simpleAction.js'

const formTitles = ['id','Section','Title','Subtitle','Body',
                    'staticImage',
                    'clickableImage', 'animateAll'];

const formTypes  = ['text','text','text','text','text',
                    'number',
                    'number','checkbox'];

const tableTitles = ['id','date_updated','Section','Title','Subtitle','Body'];
const tableTypes  = ['string','string','string','string','string','string','object']


class ConnectedMgmtConsole extends Component {

  constructor(props){
    super(props);
      this.state = {
    }
  }

  componentDidMount = async () => {
    var allPosts = await dbFuncs.process('cataphonic','cataphonic_content','findAll',{});

    var sortedPosts = allPosts.sort((a, b) => {
      var aDate = a.date_updated.split('-');
      var bDate = b.date_updated.split('-');
      var aSortable = aDate[0]+aDate[1]+aDate[2];
      var bSortable = bDate[0]+bDate[1]+bDate[2];

      return (bSortable - aSortable);
    })

    this.setState({ posts: sortedPosts });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let jsonObject = {};

    for (const [key, value]  of formData.entries()) {
        jsonObject[key] = value;
    }
    jsonObject = { ...this.selectedData, ...jsonObject };
    delete jsonObject['_id'];
    console.log(jsonObject.id);
    dbFuncs.process('cataphonic','cataphonic_content','updateOne',jsonObject, {id: jsonObject.id})
  }

  handleChange = (event) => {
    this.selectedData[event.target.name] = event.target.value;
    this.setState({data: this.selectedData});
  }

  renderFormFields = (titles, types) => {

    return(<form onSubmit={this.handleSubmit}
                 style={{position: "absolute",
                         display: 'inline-block',
                         textAlign: "left",
                         width:    "25%",
                         top:      "80px",
                         bottom:   0,
                         height:   '75vh',
                         left: "35%" }}>
        {
          titles.map((item, i) => {
            var data = this.state.data;
            return(<label key={i} style={{position: "relative", width: "50%", margin: "auto"}}>
              {item+":"}
              <input onChange={(evt) => this.handleChange(evt) } style={{position: "relative", width: "auto", right: "0px"}} type={types[i]} defaultValue={(data !== undefined) ? eval("data."+item) : ""} name={item}/><br/>
            </label>);
          })
        }
        <input type="submit" value="Submit"/>
      </form>
    )
  }

  populateForm = (data) => {
    this.selectedData = data;
    this.setState({data: this.selectedData});
  }

  renderTable      = (titles)        => {
    return(<div style={{position: 'absolute',
                        top: '5vh',
                        height: '95vh',
                        bottom: 0,
                        left: 0,
                        width: "33%",
                        overflow: "scroll"}}>
    <tr style={{textAlign: "center"}}>
      { titles.map((item, i) => {
        return(<td key={i}>{item}</td>);
        })
      }
    </tr>
    {
      this.state.posts.map((item, i) => {
        var bgColor = "";
        switch (item.Section) {
          case ("Sounds"):    bgColor = "lightgray"; break;
          case ("Sights"):    bgColor = "lightblue"; break;
          case ("Synthesis"): bgColor = "gray"; break;
          default: bgColor = "white"; break;
        }

        return(
            <tr style={{ backgroundColor: bgColor, height: `10px`}} onClick={() => {this.populateForm(item)}} key={i}>
              {
                titles.map((title, j) => {
                  return(<td style={{padding: "10px 10px 10px 10px", fontSize: '10px', width: "50px"}} key={j}>{eval("item."+title)}</td>)
                })
              }
            </tr>
          )
        })
      }
  </div>);
  }

  render() {
    if (this.state.posts === undefined) return (<SplashScreen text={"Loading mgmt console"}/>);
    else return(
      <React.Fragment>
        <SiteHeader/>
        <div style={{position: "relative", top: "80px", bottom: "25px", height: "auto", width: "100vw", display: 'flex', flexFlow: 'row-wrap'}}>
          {this.renderFormFields(formTitles, formTypes)}
          {this.renderTable(tableTitles)}
        </div>
    </React.Fragment>);
  }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
})

const MgmtConsole = connect(mapStateToProps, mapDispatchToProps)(ConnectedMgmtConsole);

export default MgmtConsole;
