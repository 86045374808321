import React, { Component } from 'react'
import { connect } from 'react-redux'
//import Logo from './icons/C Wave 3.png'
import { updateSearchQuery } from './actions/simpleAction.js'


class ConnectedDiscoverBox extends Component {

  constructor(props){
    super(props);
    this.state = {
      searchQuery: 'Search our database',
      defaultQuery: 'Search our database'
    }

    window.addEventListener('click',this.handleClick);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick  = this.handleClick.bind(this);
  }

  handleClick = (event) => {
    this.setState({ searchQuery: this.state.defaultQuery})
  }

  handleChange = (event) => {
    if(event.key === 'Enter') console.log("Enter");
    this.setState({searchQuery: event.target.value});
    this.props.updateSearchQuery(event.target.value);
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }

  handleClick = (event) => {
    if (this.state.searchQuery === '' && !this.state.selected) this.setState({searchQuery: this.state.defaultQuery})
  }

  render() {



      return (
          <form onSubmit={this.handleSubmit}>
            <input type="text" value={this.state.searchQuery} onClick={this.handleClick} onChange={this.handleChange} className="discoverBox" style={{top: `${this.props.navState.scrollPosition}px`}}>
          </input>
          </form>
      )
    }
}

const mapStateToProps = (state) => {
  return { ...state };
}
const mapDispatchToProps = dispatch => ({
    updateSearchQuery: searchQuery => dispatch(updateSearchQuery(searchQuery))
})
const DiscoverBox = connect(mapStateToProps,mapDispatchToProps)(ConnectedDiscoverBox)

export default DiscoverBox
