import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, updateArtists } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';

const dbFuncs = require('../../Test/dbShorthand.js');

const sources = ['Home','Sounds','Sights','Synthesis','Store'];

var servicesWithFollowers = ['instagram','spotify'];

class ConnectedArtistBio extends React.Component {

    constructor (props) {
      super(props);
      this.state = ({});
      this.images = new Object();

      // Black icons
      this.images['guitar']    = new Image();
      this.images['bass']    = new Image();
      this.images['vocals']    = new Image();
      this.images['drums']    = new Image();
      this.images['keys']    = new Image();
      this.images['brass']    = new Image();

      this.images['guitar'].src  = 'https://www.dropbox.com/s/uvxtl1fvh4f5i4e/guitar.png?raw=1';
      this.images['bass'].src    = 'https://www.dropbox.com/s/m6mjp9pobxtz7jq/bass.png?raw=1';
      this.images['vocals'].src  = 'https://www.dropbox.com/s/plfk06no6tkwnmy/vocals.png?raw=1';
      this.images['drums'].src   = 'https://www.dropbox.com/s/2gw1hzwqscztbsp/drums.png?raw=1';
      this.images['keys'].src    = 'https://www.dropbox.com/s/euoau0l1xz6tq7h/facebook.png?raw=1';
      this.images['brass'].src   = 'https://www.dropbox.com/s/euoau0l1xz6tq7h/facebook.png?raw=1';


    }

    renderFollowers = () => {
      return(<div className={"artistBioSocialContainer"}>
            {this.props.navState.artists.social.map((item, i) => {
              if (servicesWithFollowers.includes(item.service)) {
                return(<React.Fragment>
                        <img className={"artistBioSocial"} src={this.props.socialIcons[item.service].src} key={i}/>
                        <div style={{fontSize: '12px'}} className={"artistBioSocial"}>{(item.followers > 0) ? item.followers : 0}</div>
                       </React.Fragment>)
              }
            })}
        </div>);
    }

    renderName = () => {
      if (this.props.useAsTitle) return(
        <img src={this.props.navState.artists.logo.src} className={"artistLogoHeader"}/>
      )
      else return(
        <div className={"artistHeader"}>{this.props.navState.artists.artist_name}</div>
      )
    }

    renderLocation = () => {
      return(
      <div style={{fontFamily: this.props.navState.artists.fonts.menu}} className={"artistLocation"}>{this.props.navState.artists.location.county},{"\ "+this.props.navState.artists.location.state}</div>
      )
    }

    renderGenres = () => {
      return(
        <div style={{fontFamily: this.props.navState.artists.fonts.menu}} className={"artistGenres"}>{
          this.props.navState.artists.genres.map((item, i) => {
            return(<span key={i}>{item}{(i === this.props.navState.artists.genres.length-1) ? "" : ",\ "}</span>)
          })
        }</div>)
    }

    renderInstruments = () => {
      return(
        <div className={"artistInstruments"}>{
          this.props.navState.artists.instruments.map((item, i) => {
              return(<img key={i} style={{height: "24px", width: "24px",opacity: 1}}  className={"socialIcon"} src={this.images[item].src}/>);
          })
        }</div>)
    }

    render() {
      return(
        <div style={{fontColor: (this.props.navState.artists.siteStyle.theme === "dark") ? "white" : "black",
                     opacity: (this.fullScreen) ? 0 : 1,
                     fontFamily: this.props.navState.artists.fonts.title}} className={"artistBio"}>
          <a className={"artistLogo"}>
            <img className={"artistLogoImg"} src={this.props.navState.artists.logo.src}/>
          </a>
          {//this.renderFollowers()}
          }
          <div className={ "artistInfo" }>
            { this.renderName()         }
            { this.renderLocation()     }
            { this.renderInstruments()  }
            { this.renderGenres()       }
          </div>
       </div>)
  }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateArtists: artistInfo => dispatch(updateArtists(artistInfo))
})

const ArtistBio = connect(mapStateToProps,mapDispatchToProps)(ConnectedArtistBio)

export default ArtistBio;
