export const UPDATE_NAVBAR = "UPDATE_NAVBAR"
export const SET_PARAMS = "SET_PARAMS"
export const GET_SPOTIFY_USER = "GET_SPOTIFY_USER"
export const GET_SPOTIFY_STREAMING = "GET_SPOTIFY_STREAMING"
export const GET_INSTAGRAM_USER = "GET_INSTAGRAM_USER"
export const SET_SPOTIFY_LOADED = "SET_SPOTIFY_LOADED"
export const SET_TOKENS_RETRIEVED = "SET_TOKENS_RETRIEVED"
export const SET_TOP_TRACKS = "SET_TOP_TRACKS"
export const SET_TRACKS_RETRIEVED = "SET_TRACKS_RETRIEVED"
export const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION"
export const UPDATE_WINDOW_SIZE = "UPDATE_WINDOW_SIZE"
export const UPDATE_SEARCH_QUERY = "UPDATE_SEARCH_QUERY"
export const UPDATE_ARTISTS = "UPDATE_ARTISTS"
export const SET_NOW_PLAYING = "SET_NOW_PLAYING"
export const UPDATE_COMPONENT_COUNT = "UPDATE_COMPONENT_COUNT"
export const UPDATE_PLAYER_DATA = "UPDATE_PLAYER_DATA"
export const UPDATE_COMIC_FRAME = "UPDATE_COMIC_FRAME"
export const UPDATE_COMIC_PAGE  = "UPDATE_COMIC_PAGE"
export const UPDATE_COMIC_PLAY  = "UPDATE_COMIC_PLAY"
export const CONFIRM_CONTENT_LOADED = "CONFIRM_CONTENT_LOADED"
export const PLAYING_VIDEO = "PLAYING_VIDEO"
export const TOGGLE_CONTEXT = "TOGGLE_CONTEXT"
