import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNowPlaying,
         updateArtists,
         updateNavbar,
         setParams,
         setTokensRetrieved,
         setTopTracks,
         setTracksRetrieved,
         updateWindowSize } from './actions/simpleAction';
import './App.css';
import NavBox from './NavBox.js';
import store from './store/index.js';
import ContentBox from './ContentBox.js';
import LogoBox from './LogoBox.js';
import DiscoverBox from './DiscoverBox.js';
import DBTestForm from './Test/DBTestForm.js';
import MusicPlayer from './Components/Sounds/MusicPlayer.js';
import { getCookie, setCookie, expireCookie } from 'redux-cookie';
import DDDD from './DDDD.js';
import DDDDStats from './DDDDStats.js';
import ComicReader from './ComicReader.js';
import SiteHeader from './SiteHeader.js';
import UpdatesPanel from './UpdatesPanel.js';
import SplashScreen from './Components/Global/SplashScreen.js'
import ContextMenu from './Components/Global/ContextMenu.js'

const publicIP = 'cataphonic.studio';
const s_tfr_path     = 'https://'+publicIP+':8888/spotifyRefresh?refresh_token=';

var dbFuncs = require('./Test/dbShorthand.js')
var mainSections = ["Sounds","Sights","Synthesis"];

function getPageNav() {
    var e, r = /([^#;=]+)=?([^?;]*)/g;
    var q = window.location.hash.substring(1);
    while ( e === r.exec(q)) {
      return (e[1]);
    }
}
function getHashParams() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
}
//var querystring = require('querystring');
var sightsImg     = "https://www.dropbox.com/s/n2n8m8aome6u8wu/sights.png?raw=1";
var sightsImgW    = "https://www.dropbox.com/s/vpo47j554pnbau4/sights_w.png?raw=1";

var soundsImg     = "https://www.dropbox.com/s/t5kikd5fwldl450/sounds.png?raw=1";
var soundsImgW    = "https://www.dropbox.com/s/yidf6l9o20ohfr9/sounds_w.png?raw=1";

var synthesisImg  = "https://www.dropbox.com/s/tlhzq0c7dbrcfbe/synthesis.png?raw=1";
var synthesisImgW = "https://www.dropbox.com/s/refpmrnh6mju7gq/synthesis_w.png?raw=1";

var storeImg      = "https://www.dropbox.com/s/xmfoecu8hqbju8e/Store.png?raw=1";
var storeImgW     = "https://www.dropbox.com/s/erw9zxwlb81ckv9/Store_w.png?raw=1";

var pauseButtonURL = 'https://www.dropbox.com/s/snawxvwaw69gb1l/PAUSE.png?raw=1';
var playButtonURL = 'https://www.dropbox.com/s/xrfj9w5ixrhrkd3/PLAY.png?raw=1';
var playButtonWURL = 'https://www.dropbox.com/s/6djvoreja6u49lk/PLAYw.png?raw=1'
var pauseButtonWURL = 'https://www.dropbox.com/s/sbrzb8z1pm65d3t/PAUSEw.png?raw=1';
var hamburgerURL    = 'https://www.dropbox.com/s/g7gk8yzf8gb41xh/hamburger.png?raw=1';
var hamburgerWURL   = 'https://www.dropbox.com/s/uj73xmauhnnizks/hamburgerw.png?raw=1';

class App extends Component {

    constructor(props) {
      super(props);
      document.title = 'Cataphonic';

      if (window.location.hash.split('#').length === 1) { this.path = "Home"; }
      else {
        switch(window.location.hash.split('#')[1].split('/')[0]) {
          case (""): this.path = "Home"; break;
          case ("Home"): this.path = "Home"; break;
          case ("Sights"): this.path = "Sights"; break;
          case ("Sounds"): this.path = "Sounds"; break;
          case ("Synthesis"): this.path = "Synthesis"; break;
          case ("Coalition"): this.path = "Coalition"; break;
          case ("PWRVOL"): this.path = "PWRVOL"; break;
          case ("SCATTEREDBIRDS"): this.path = "SCATTEREDBIRDS"; break;
          default: {this.path = window.location.hash.split('#')[1].split('/')[0]; break;}
        }
      }
      window.location.hash = "#"+this.path;
      this.props.updateNavbar(this.path);
    }

    loadStreamingIcons = async() => {
      this.streamingIcons = new Object();

      this.streamingIcons.playButton                = new Image();
      this.streamingIcons.pauseButton               = new Image();
      this.streamingIcons.playButtonw               = new Image();
      this.streamingIcons.pauseButtonw              = new Image();
      this.streamingIcons.hamburger                 = new Image();
      this.streamingIcons.hamburgerw                = new Image();
      this.streamingIcons.playButton.src            = playButtonURL;
      this.streamingIcons.pauseButton.src           = pauseButtonURL;
      this.streamingIcons.playButtonw.src           = playButtonWURL;
      this.streamingIcons.pauseButtonw.src          = pauseButtonWURL;
      this.streamingIcons.hamburger.src             = hamburgerURL;
      this.streamingIcons.hamburgerw.src            = hamburgerWURL;
    }

    loadHeaderIcons = async () => {
      this.headerIcons = new Object();

      this.headerIcons.sightsImg     = new Image();
      this.headerIcons.sightsImgW    = new Image();
      this.headerIcons.soundsImg     = new Image();
      this.headerIcons.soundsImgW    = new Image();
      this.headerIcons.synthesisImg  = new Image();
      this.headerIcons.synthesisImgW = new Image();
      this.headerIcons.storeImg      = new Image();
      this.headerIcons.storeImgW     = new Image();
      this.headerIcons.sightsImg.src     = sightsImg;
      this.headerIcons.sightsImgW.src    = sightsImgW;
      this.headerIcons.soundsImg.src     = soundsImg;
      this.headerIcons.soundsImgW.src    = soundsImgW;
      this.headerIcons.synthesisImg.src  = synthesisImg;
      this.headerIcons.synthesisImgW.src = synthesisImgW;
      this.headerIcons.storeImg.src      = storeImg;
      this.headerIcons.storeImgW.src     = storeImgW;
    }
    loadSocialIcons = async () => {
      this.socialIcons = new Object();

      // Black icons
      this.socialIcons['facebook']    = new Image();
      this.socialIcons['instagram']   = new Image();
      this.socialIcons['twitter']     = new Image();
      this.socialIcons['spotify']     = new Image();
      this.socialIcons['apple']       = new Image();
      this.socialIcons['soundcloud']  = new Image();
      this.socialIcons['facebook'].src   = 'https://www.dropbox.com/s/euoau0l1xz6tq7h/facebook.png?raw=1'
      this.socialIcons['instagram'].src  = 'https://www.dropbox.com/s/ywvkhp9gj7elo7g/Instagram-512.png?raw=1';
      this.socialIcons['twitter'].src    = 'https://www.dropbox.com/s/l5k9uth7uku74dd/twitter.png?raw=1';
      this.socialIcons['spotify'].src    = 'https://www.dropbox.com/s/5v4gvzjyhgduznw/spotify.png?raw=1';
      this.socialIcons['apple'].src      = 'https://www.dropbox.com/s/plzrv3rtak90us1/apple.png?raw=1';
      this.socialIcons['soundcloud'].src = 'https://www.dropbox.com/s/vpjajb7upqbcyzk/soundcloud-49-893495.png?raw=1';

      // White icons
      this.socialIcons['facebookw']    = new Image();
      this.socialIcons['instagramw']   = new Image();
      this.socialIcons['twitterw']     = new Image();
      this.socialIcons['spotifyw']     = new Image();
      this.socialIcons['applew']       = new Image();
      this.socialIcons['soundcloudw']  = new Image();
      this.socialIcons['facebookw'].src   = 'https://www.dropbox.com/s/auzvhevs89k2o1w/facebookw.png?raw=1'
      this.socialIcons['instagramw'].src  = 'https://www.dropbox.com/s/q6k89lthdaeyduc/Instagram-512w.png?raw=1';
      this.socialIcons['twitterw'].src    = 'https://www.dropbox.com/s/u6wuq7s4gkgphvc/twitterw.png?raw=1';
      this.socialIcons['spotifyw'].src    = 'https://www.dropbox.com/s/7ljnicgt5t2yyvh/spotifyw.png?raw=1';
      this.socialIcons['applew'].src      = 'https://www.dropbox.com/s/azchsgjfv1814oa/applew.png?raw=1';
      this.socialIcons['soundcloudw'].src = 'https://www.dropbox.com/s/3bjep9fwanlusnu/soundcloud-49-893495w.png?raw=1';

    }
    componentDidMount = async () => {

    store.dispatch(setCookie('un',{username: 'pwrvol'}, { path: '', expires: 365}));

    await this.loadStreamingIcons();
    await this.loadHeaderIcons();
    await this.loadSocialIcons();

    window.addEventListener('scroll',this.handleScroll);
    window.addEventListener('resize',this.handleResize);

    var mode = ( window.innerWidth < 900) ? "m" : "d";

    this.props.updateWindowSize({ mode: mode, width: window.innerWidth, height: window.innerHeight});

    if (this.props.history.location.hash.split('#').length === 1) return;
    var query = this.props.history.location.hash.split('#')[1].toLowerCase();
      if (query === "home"
          || query === "sounds"
          || query === "sights"
          || query === "synthesis"
          || query === "store"
          || query === "coalition"
          || query === "" ) return;
      else {
        dbFuncs.process('cataphonic','artists','findOne',{username: query})
        .then( d => {
          this.props.updateArtists(d);
        })
      }

  }

    componentWillUpdate = (nextProps) => {
      if ( this.props.navState.section === undefined
        || nextProps.history.location.hash === undefined
        || nextProps.history.location.hash === ''
        || nextProps.history.location.hash === null
        || this.props.navState.section === '') return;

      if ( "#"+this.props.navState.section !== nextProps.history.location.hash )
      {
        this.props.updateNavbar(nextProps.history.location.hash.split('#')[1]);
      } else if (this.props.navState.section !== nextProps.navState.section) {
          var query = nextProps.navState.section.toLowerCase();
          if (query === "home"
              || query === "sounds"
              || query === "sights"
              || query === "synthesis"
              || query === "store"
              || query === "coalition"
              || query === "" ) return;
          else {
            dbFuncs.process('cataphonic','artists','findOne',{username: query})
            .then( d => {
              this.props.updateArtists(d);
            })
          }
      }
    }

    componentWillUnmount = () => {
      window.removeEventListener('scroll',this.handleScroll);
      window.removeEventListener('resize',this.handleResize);
    }

    handleResize = (e) => {
      if (window.innerWidth < 900)
        this.props.updateWindowSize({ mode: "m", width: window.innerWidth, height: window.innerHeight});
      else
        this.props.updateWindowSize({ mode: "d", width: window.innerWidth, height: window.innerHeight});
    }
    renderHomepage = () => {
      return (
        <div className="App" ref={this.nav}>
          <SiteHeader icons={this.headerIcons} history={(navigation) => this.props.history.push(navigation)}/>
          <UpdatesPanel streamingIcons={this.streamingIcons} socialIcons={this.socialIcons}/>
          <MusicPlayer/>
          <ContextMenu/>
        </div>);
    }
    nav = React.createRef();

 render() {
    if (this.props.navState.section === '' || this.props.navState.section === undefined) return (
      <SplashScreen text={"Loading..."}
                    color={{bg: "black",font: "white", logo: "white"}}/>);
    else return (this.renderHomepage());
  }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    //setNowPlaying: nowPlaying => dispatch(setNowPlaying(nowPlaying)),
    //setParams: params => dispatch(setParams(params)),
    //setTokensRetrieved: tokensRetrievedFlag => dispatch(setTokensRetrieved(tokensRetrievedFlag)),
    //setTopTracks: topTracks => dispatch (setTopTracks(topTracks)),
    //setTracksRetrieved: tracksRetrievedFlag => dispatch(setTracksRetrieved(tracksRetrievedFlag)),
    updateNavbar: section => dispatch(updateNavbar(section)),
    updateWindowSize: windowSize => dispatch(updateWindowSize(windowSize)),
    updateArtists: artists => dispatch(updateArtists(artists))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
//  <MapContainer/>}

/*

  this.state = {
    hashParams: getHashParams(),
    section:    getPageNav(),
    tokenQuery : {
      db: 'cataphonic',
      collectionName: 'tokens',
      dbMethod: 'findOne',
      queryValues: {
        _id: "sp_auth"
      }
    }
  }


updateArtists = () => {
  dbFuncs.process('cataphonic','artists','join',[
        { $sort: { username: 1 }},
        {
             $lookup: {

               from: 'tracks',
               let:  { artist: '$spotify_id' },
               pipeline: [
                 {
                   $match:
                    { $expr:
                        { $and:
                          [
                            { $eq:  ["$source_id","$$artist"] },
                            { $gt: ["$date_updated",dbFuncs.getDate('date',-7)]},
                            { $gt: ["$popularity", 0]}
                          ]
                        }
                      }
                    },
                    { $group: { _id: '$date_updated',
                                totalTime: { $sum: '$duration_ms'},
                                totalPopularity: { $sum: '$popularity' },
                                minPopularity: { $min: '$popularity'},
                                maxPopularity: { $max: '$popularity'},
                                tracks: { $push: { id: "$id", name: '$name', popularity: '$popularity'}}}},
                    { $sort: { _id: -1}},
                    { $project: { _id: 1, totalPopularity: 1, minPopularity: 1, maxPopularity: 1, tracks: 1} }
                  ],

              as: "artistStats"
                }
              }])
              .then(
                d => { this.props.updateArtists(d);
                               this.getCurrentlyPlaying();
                               })
                .catch( e => console.log(e));
}

getTokens = () => {
/
  // If no rt cookie, check in URL
  if (store.dispatch(getCookie('s_tfr'))===undefined) {
    console.log('Cookie not found');
    if (this.state.hashParams.s_tfr)
      {
        // If in URL value, insert into hash params + cookies.
        console.log('Found tfr in hash params');
        store.dispatch(setCookie('s_tfr',this.state.hashParams.s_tfr, { path: '', expires: 365}));
        store.dispatch(setCookie('s_tfa',this.state.hashParams.s_tfa, { path: '', expires: 365}));

        this.setTokensRetrieved(true);
        this.updateArtists();
        this.setParams( this.state.hashParams )
      } else {
        this.setTokensRetrieved(true);
        this.updateArtists();
      }

    }  // If a value is found in cookies, go ahead and use it to r.a.
    else {
      let s_tfr = store.dispatch(getCookie('s_tfr'));

      fetch(s_tfr_path+s_tfr)
      .then( res => { return res.json()})
      .then( d => {
        this.setTokensRetrieved(true);
        this.updateArtists();
        this.setParams( { s_tfr: s_tfr, s_tfa: d.access_token } );
      })
    }
}
getCurrentlyPlaying = () => {
     Make a call using the token
    if (this.props.navState.params.sp_access_token) {
    fetch("https://api.spotify.com/v1/me/player",{
      method: "GET",
      headers: {
        "Authorization" : "Bearer " + this.props.navState.params.sp_access_token,
        "Content-Type"  : "application/json"
      }})
      .then(res => {return res.json()})
      .then(d => this.setNowPlaying(d))
      .catch(e => console.log("Now Playing Failed"))
    }
    }

setTokensRetrieved = (result) => {
  this.props.setTokensRetrieved(result);
}

setParams = (params) => {
  this.props.setParams(params);
  getPageNav();
  this.props.updateNavbar(getPageNav());
}

this.updateWindowSize = this.updateWindowSize.bind(this);
this.getTokens = this.getTokens.bind(this);
this.setParams = this.setParams.bind(this);

if (this.props.navState.tokensRetrievedFlag === false) {
   this.getTokens();
}


*/
