import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNavbar } from '../actions/simpleAction.js'

const firstPageButton      = 'https://www.dropbox.com/s/iw0278jg10p7z77/pg1%20button.png?raw=1';
const previousPageButton   = 'https://www.dropbox.com/s/ua42cdwqycuaqwh/page%20back%20button.png?raw=1';
const previousFrameButton  = 'https://www.dropbox.com/s/ycoa4hhykzutk7e/previous%20frame%20button.png?raw=1';
const playButton           = 'https://www.dropbox.com/s/y2j4mfszqtl04db/play%20button.png?raw=1';
const pauseButton          = 'https://www.dropbox.com/s/5ca7hxud77kzc5b/pause%20button.png?raw=1';
const nextFrameButton      = 'https://www.dropbox.com/s/92p6t48n9n1taan/next%20frame%20button.png?raw=1';
const nextPageButton       = 'https://www.dropbox.com/s/km7olrk8yi022n9/page%20forward%20button.png?raw=1';
const endOfPageButton      = 'https://www.dropbox.com/s/7m6xm1sqf01y61g/end%20of%20page%20button.png?raw=1'
const newestPageButton     = 'https://www.dropbox.com/s/ergip7ukdi7c8ue/new%20button.png?raw=1';

class ConnectedComicButton extends Component {

  constructor(props){
    super(props);
  }

  componentDidMount = () => {
  }

  getButtonImage = (buttonFunction) => {
    this.buttonImage = new Image();
    switch(buttonFunction) {
      case ("First Page"):      this.buttonImage.src = firstPageButton; break;
      case ("Previous Page"):   this.buttonImage.src = previousPageButton; break;
      case ("Previous Frame"):  this.buttonImage.src = previousFrameButton; break;
      case ("Play Page"):       this.buttonImage.src = playButton; break;
      case ("Pause Page"):      this.buttonImage.src = pauseButton; break;
      case ("Next Frame"):      this.buttonImage.src = nextFrameButton; break;
      case ("End of Page"):     this.buttonImage.src = endOfPageButton; break;
      case ("Next Page"):       this.buttonImage.src = nextPageButton; break;
      case ("Newest Page"):     this.buttonImage.src = newestPageButton; break;
      default: return ""; break;
    }
  }

  render() {

    if ( this.buttonImage === undefined
       || this.props.buttonText === 'Play Page'
       || this.props.buttonText === 'Pause Page') this.getButtonImage(this.props.buttonText);
    return(
      <img src={this.buttonImage.src} className={"COLComicButton"} onClick={this.props.clickEvent}>

      </img>
    )
  }
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateNavbar: section => dispatch(updateNavbar(section))
});

const ComicButton = connect(null, mapDispatchToProps)(ConnectedComicButton);

export default ComicButton;
