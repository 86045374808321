import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNavbar } from '../../actions/simpleAction.js'
import Logo from '../../icons/C Wave 3.png'
import Logo2 from '../../icons/CWaveWhite.png'

var logo = 'https://www.dropbox.com/s/r6cg365gwphmpin/C%20Wave%203.png?raw=1'

class ConnectedSplashScreen extends Component {

  constructor(props){
    super(props);
  }

  render() {

    if (this.props.color === undefined) return(<div></div>)
    return(
      <div className={"loadingScreen"} style={{backgroundImage: `url(${(this.props.color.logo === "white") ? Logo2 : Logo})`,
                                               backgroundSize: 'contain',
                                               backgroundPosition: 'center',
                                               backgroundRepeat: 'no-repeat',
                                               backgroundColor: (this.props.color.bg === "white") ? "white" : "black",
                                               color: (this.props.color.font === "white") ? "white" : "black",
                                               zIndex: -1}}>
        {this.props.text}
      </div>
    )
  }
}
const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
  updateNavbar: section => dispatch(updateNavbar(section))
});

const SplashScreen = connect(mapStateToProps, mapDispatchToProps)(ConnectedSplashScreen);

export default SplashScreen;
