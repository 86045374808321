import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateNavbar, confirmContentLoaded } from '../../actions/simpleAction.js'

var sightsArrow    = 'https://www.dropbox.com/s/waxj5exszzxhsfz/SightsArrow.png?raw=1';
var soundsArrow    = 'https://www.dropbox.com/s/4i4r3pmvnqa1b9j/SoundsArrow.png?raw=1';
var synthesisArrow = 'https://www.dropbox.com/s/udo5exfnaglplqk/SynthesisArrow.png?raw=1';

class ConnectedArchivePost extends Component {

  constructor(props){
    super(props);

    this.navToContent = this.navToContent.bind(this);
    this.state = {currentClass: "highlightImage", activated: false}

  }

  swapAsHighlight = () => {
      const section = this.props.content.Section.toLowerCase();
      var overallContent = this.props.navState.content;

      var currentHighlight = overallContent.new[section][0];
      var newHighlight     = this.props.content;

      overallContent['old'][section][this.props.placement] = currentHighlight;
      overallContent['new'][section][0]                    = newHighlight;


      this.props.confirmContentLoaded(overallContent);
  }

  navToContent = (navLink) => {
    this.props.updateNavbar(navLink);
  }

  render() {
      var soloAdjust = (this.props.isSolo) ? " solo" : "";

      return(<div className={"archivedPostContainer" + soloAdjust}>
              { this.props.content.Images.map((item, i) => {

                var baseStyle = {
                                 zIndex: (this.props.content.clickableImage === i) ? 20 : i
                                };

                var combinedStyle = new Object();

                if (this.props.content.ImageStyle !== undefined
                 && this.props.content.ImageStyle[i] !== undefined) {
                   var loadedStyle = this.props.content.ImageStyle[i];
                   combinedStyle = {...baseStyle, ...loadedStyle};
                 } else {
                   combinedStyle = baseStyle;
                 }

                if (i === parseInt(this.props.content.clickableImage))
                  return(
                      <img key={i}
                           src={ (window.innerWidth > 900) ? item.desktop : item.mobile }
                           style={combinedStyle}
                           className={"highlightOldPost" + soloAdjust}
                           onMouseEnter={()=>{ this.setState({activated: true});  }}
                           onMouseLeave={()=>{ this.setState({activated: false}); }}
                           onClick=     {()=>{ this.swapAsHighlight();            }}>
                      </img>
                  );
                else return(
                      <img key={i}
                           src={ (window.innerWidth > 900) ? item.desktop : item.mobile }
                           style={combinedStyle}
                           className={"highlightOldPost" + soloAdjust}>
                      </img>
                  );
              })}
              </div>
      )
    }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    updateNavbar: section => dispatch(updateNavbar(section)),
    confirmContentLoaded: content => dispatch(confirmContentLoaded(content))
})

const ArchivePost = connect(mapStateToProps, mapDispatchToProps)(ConnectedArchivePost);

export default ArchivePost;
