import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updatePlayerData } from './actions/simpleAction';
import DDDDStatsPlayer from './DDDDStatsPlayer';
import DDDDAvatarPlayer from './DDDDAvatarPlayer';
import openSocket from 'socket.io-client';
import scrollToComponent from 'react-scroll-to-component';

const socket = openSocket('https://cataphonic.studio:9011');
var dbFuncs = require('./Test/dbShorthand.js')


function subscribeToDBChanges(cb) {
  socket.on('updateStats', (stats) => {
    cb(null, stats)
  });
  socket.on('updateGFX', (gfx) => {
    cb(null, gfx)
  })
}

class ConnectedDDDDStats extends Component {

  constructor(props) {
    super(props);

    this.headerStart    = {position: "absolute", top: 0, left: 0, padding: "0px", margin: "0px", backgroundColor: "white", width: "100vw", height: "50px", textAlign: "center"};
    this.headerScrolled = {position: "absolute", top: 0, left: 0, padding: "0px", margin: "0px", backgroundColor: "white", width: "100vw", height: "50px", textAlign: "center"};
    this.UpdateValues      = this.UpdateValues.bind(this);
    this.RenderPlayerCards = this.RenderPlayerCards.bind(this);

    const url = window.location.href;
    let lastPart= url.split("/").pop();
    let navHash = "";

    if (lastPart.split("#").pop() != "") {
      navHash  = lastPart.split("#").pop();
      lastPart = lastPart.split("#")[0];
    }

    this.scrolledTo = false;

    this.url = url;
    this.lastPart = lastPart;
    this.navHash = navHash;

    this.LoadValues = this.LoadValues.bind(this);

    this.LoadValues(lastPart);
    subscribeToDBChanges( (err, change) => { console.log(change); this.LoadValues(lastPart) } );
  }

  componentDidMount = () => {
      if (this.navHash == "Stats") {
          scrollToComponent(this);
      }
  }
  LoadValues = (lastPart) => {
    if (lastPart == "dddd-stats"
        || lastPart == ""
        || lastPart == "dddd") {
          dbFuncs.process('cataphonic','dddd_players','findAll', {} )
          .then (d => this.UpdateValues(d) )
        }
    else {
      dbFuncs.process('cataphonic','dddd_players','findOne',{ Username: lastPart.toUpperCase()} )
      .then (d => this.UpdateValues(d) )
    }
  }

  //Get a fresh set of data and update the site props
  UpdateValues = (data) => {
    this.props.updatePlayerData(data);
  }

  RenderPlayerCards = () => {
    if (this.props.navState.player) {
      if (this.lastPart == "dddd-stats" || this.lastPart == "" || this.lastPart == "dddd")
        return(
          <div className="DDDDStatsOverviewPanel">
          {
            this.props.navState.player.map((item, i) => {
            return(<div key={item.Username}>
                                          <DDDDAvatarPlayer
                                                headColor = {item.HeadColor}
                                                bodyColor = {item.BodyColor}
                                                armsColor = {item.ArmsColor}
                                                legsColor = {item.LegsColor}
                                                />

                                          <DDDDStatsPlayer
                                               Username        = {item.Username}
                                               DateCreated     = {item.Date_Created}
                                               CurrentlyOnline = {item.Status}
                                               LastOnline      = {item.Date_Updated+" @ "+item.Time_Updated}
                                               KOs             = {item.KOs}
                                               Downs           = {item.Downs}
                                               Assists         = {item.Assists}
                                               ShotsTaken      = {item.ShotsTaken}
                                               PointsScored    = {item.PointsScored}
                                               TwoPointers     = {item.TwoPointers}
                                               ThreePointers   = {item.ThreePointers}
                                               Dunks           = {item.Dunks}
                                               GamesPlayed     = {item.GamesPlayed}
                                               GamesWon        = {item.GamesWon}
                                               GamesLost       = {item.GameLost}
                                               />
                                               </div>)})
          }
          </div>);
          else return(
            <div>
                       <DDDDAvatarPlayer headColor = {this.props.navState.player.HeadColor}
                                         bodyColor = {this.props.navState.player.BodyColor}
                                         armsColor = {this.props.navState.player.ArmsColor}
                                         legsColor = {this.props.navState.player.LegsColor}/>

            <DDDDStatsPlayer                   Username        = {this.props.navState.player.Username}
                                               DateCreated     = {this.props.navState.player.Date_Created}
                                               CurrentlyOnline = {this.props.navState.player.Status}
                                               LastOnline      = {this.props.navState.player.Date_Updated+" @ "+this.props.navState.player.Time_Updated}
                                               KOs             = {this.props.navState.player.KOs}
                                               Downs           = {this.props.navState.player.Downs}
                                               Assists         = {this.props.navState.player.Assists}
                                               ShotsTaken      = {this.props.navState.player.ShotsTaken}
                                               PointsScored    = {this.props.navState.player.PointsScored}
                                               TwoPointers     = {this.props.navState.player.TwoPointers}
                                               ThreePointers   = {this.props.navState.player.ThreePointers}
                                               Dunks           = {this.props.navState.player.Dunks}
                                               GamesPlayed     = {this.props.navState.player.GamesPlayed}
                                               GamesWon        = {this.props.navState.player.GamesWon}
                                               GamesLost       = {this.props.navState.player.GameLost}
                                               />


       </div>);
      }
  }

  render() {
    return(
      <div className={"DDDDStatsOverviewPanel"}>
        { this.RenderPlayerCards() }
      </div>
    )
  }

}

const mapDispatchToProps = dispatch => ({
    updatePlayerData: player => dispatch(updatePlayerData(player))
})

const mapStateToProps = state => ({
 ...state
})

const DDDDStats = connect(mapStateToProps, mapDispatchToProps)(ConnectedDDDDStats);
export default DDDDStats;
