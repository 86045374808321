import React, { Component } from 'react';
import { connect } from 'react-redux'
import { toggleContext } from '../../actions/simpleAction.js'
import { store } from '../../store/index.js'

const dbFuncs = require('../../Test/dbShorthand.js');

const contextWidth = 128;

const style = {
  width: "100%", height: "25%"
}

class ConnectedContextMenu extends React.Component {

    constructor (props) {
      super(props);
      this.state = ({});
    }

    renderSubMenu = (e) => {
        if (this.state.subVisible) return(<div
          style={{
                   zIndex: 3000,
                   opacity: (this.props.navState.contextMenu.visible) ? 1 : 0 ,
                   position: 'absolute',
                   top: this.props.navState.contextMenu.position.y - this.props.navState.contextMenu.height,
                   left: this.props.navState.contextMenu.position.x - this.props.navState.contextMenu.width*2,
                   backgroundColor: 'black',
                   width: `${this.props.navState.contextMenu.width}px`,
                   height: `${this.props.navState.contextMenu.height}px`, display: 'flex', flexFlow: 'column', color: 'white'}}>
          </div>)
    }

    toggleSubMenu = (e, item) => {
      this.setState({
        subMenu: item,
        subVisible: !this.state.subVisible
      })
    }

    renderBackboard = () => {
      if (this.props.navState.contextMenu.visible === true) {
          return(<div onClick={(e) => { this.props.toggleContext({options: [''],height: 0, width: 0, position: { x: 0, y: -200}, visible: false})}}
                      style={{position: 'absolute',
                              top: '0px',
                              width: '100%',
                              height: '100%',
                              zIndex: 2500}}></div>);
                    }
    }

    renderMenu = () => {

      let baseStyle = {
           zIndex: 3000,
           opacity: (this.props.navState.contextMenu.visible) ? 1 : 0,
           position: 'absolute',
           backgroundColor: 'black',
           width: `${this.props.navState.contextMenu.width}px`,
           height: `${this.props.navState.contextMenu.height}px`,
           display: 'flex',
           flexFlow: 'column',
           color: 'white'}
      let conditionalStyle = {
        top:  `${this.props.navState.contextMenu.position.y - this.props.navState.contextMenu.height}px`,
        left: `${this.props.navState.contextMenu.position.x - this.props.navState.contextMenu.width}px`,
      }

      if (this.props.navState.contextMenu.service === 'C_Button') {
        conditionalStyle.top = '60px';
        conditionalStyle.left = '0px';
      }

      return(
            <React.Fragment>
              {this.renderBackboard()}
              <div
              style={{...baseStyle,...conditionalStyle}}>
                   {
                     this.props.navState.contextMenu.options.map((item, i) => {
                        return(<div onClick={(e) => this.toggleSubMenu(e, item)}
                         key={i} style={style}>{item}</div>);
                      })
                    }
                  </div>
                  {this.renderSubMenu()}
            </React.Fragment>
                 );
    }

    render() {
      return(<React.Fragment>{this.renderMenu()}</React.Fragment>)
    }

}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  toggleContext: context => dispatch(toggleContext(context))
})

const ContextMenu = connect(mapStateToProps,mapDispatchToProps)(ConnectedContextMenu)

export default ContextMenu;
