import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, confirmContentLoaded, updateWindowSize, playingVideo } from './actions/simpleAction.js'
import HighlightPanel from './Components/Global/HighlightPanel.js'
import SectionPanel from './Components/Sights/SectionPanel.js'
import ComicReader from './ComicReader.js'
import SplashScreen from './Components/Global/SplashScreen.js'
import openSocket from 'socket.io-client';
import SocialBox from './Components/Global/SocialBox.js'
import StreamingOptions from './Components/Sounds/StreamingOptions.js'
import SongBox from './Components/Sounds/SongBox.js'
import MusicPlayer from './Components/Sounds/MusicPlayer.js'
import ArtistBio from './Components/Global/ArtistBio.js'
import ArtistMenu from './Components/Global/ArtistMenu.js'
import AlbumBox from './Components/Sounds/AlbumBox.js'

var dbFuncs = require('./Test/dbShorthand.js')
const socket = openSocket('https://cataphonic.studio:9011');

function subscribeToDBChanges(cb) {
  socket.on('updatePosts', (posts) => {
    cb(null, posts);
  });
}
var pwrVolHqVideoURL = "https://www.dropbox.com/s/bsdzzxbds8j9604/movieHQ.mp4?raw=1"

var pwrVolLqVideoURL = "https://www.dropbox.com/s/w1w7p540c3zqfnh/movieLQ.mp4?raw=1"

var pauseButtonURL = 'https://www.dropbox.com/s/snawxvwaw69gb1l/PAUSE.png?raw=1';
var playButtonURL = 'https://www.dropbox.com/s/xrfj9w5ixrhrkd3/PLAY.png?raw=1';
var replayButtonURL = 'https://www.dropbox.com/s/tlgvgopwgua12oy/RST.png?raw=1';
var soundOffButtonURL = 'https://www.dropbox.com/s/owky5qq77hgsyy0/PLAY_NO_SOUND.png?raw=1';
var soundOnButtonURL =  'https://www.dropbox.com/s/c1cwqiravodnvfw/SOUND.png?raw=1';
var fullscreenButtonURL = 'https://www.dropbox.com/s/zrpph6shwlysjtx/FULLSCREEN.png?raw=1';

class ConnectedUpdatesPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedPanel: '',
      loaded: false,
      loadType: 'home'
    }
    this.videoRef     = React.createRef();
    this.videoStarted = true;
    this.playMuted    = true;
    this.fullScreen   = false;

    this.replayButton     = new Image();
    this.playButton       = new Image();
    this.pauseButton      = new Image();
    this.soundOnButton    = new Image();
    this.soundOffButton   = new Image();
    this.fullscreenButton = new Image();

    this.movie = document.createElement("video");

    subscribeToDBChanges(
      (err, change) => {
        if (change !== 'Subscribed to post updates.') this.updatePost(change);
      } );
  }

  componentDidMount = async () => {

    this.replayButton.src = replayButtonURL;
    this.playButton.src = playButtonURL;
    this.pauseButton.src = pauseButtonURL;
    this.soundOnButton.src = soundOnButtonURL;
    this.soundOffButton.src = soundOffButtonURL;
    this.fullscreenButton.src = fullscreenButtonURL;

    var contentBlock  = new Object();

    // Get all content.
    var frontPageContent        = await dbFuncs.process('cataphonic','cataphonic_content','findAll',{Panel_Type: "Highlight"});

    var sightsContent        = frontPageContent.filter((a) => { return (a.Section === "Sights")});
    var soundsContent        = frontPageContent.filter((a) => { return (a.Section === "Sounds")});
    var synthesisContent     = frontPageContent.filter((a) => { return (a.Section === "Synthesis")});

    var newSightsUpdate      = sightsContent.filter((a) => { return (a.archived === false) });
    var newSoundsUpdate      = soundsContent.filter((a) => { return (a.archived === false) });
    var newSynthesisUpdate   = synthesisContent.filter((a) => { return (a.archived === false) });

    var oldSightsUpdates     = sightsContent.filter((a) => { return (a.archived === true) })
    var oldSoundsUpdates     = soundsContent.filter((a) => { return (a.archived === true) })
    var oldSynthesisUpdates  = synthesisContent.filter((a) => { return (a.archived === true) })

    contentBlock.new = new Object();
    contentBlock.old = new Object();

    contentBlock.new.sights    = newSightsUpdate;
    contentBlock.new.sounds    = newSoundsUpdate;
    contentBlock.new.synthesis = newSynthesisUpdate;

    contentBlock.old.sights    = oldSightsUpdates;
    contentBlock.old.sounds    = oldSoundsUpdates;
    contentBlock.old.synthesis = oldSynthesisUpdates;

    this.props.confirmContentLoaded(contentBlock);

    if (this.props.navState.artists !== undefined
        && this.props.navState.artists !== this.props.navState.artists
        && this.props.navState.artists !== undefined
        && this.props.navState.artists.bgVid !== undefined ) {
      this.movie.src = (window.innerWidth > 900)
                     ? this.props.navState.artists.bgVid.hq
                     : this.props.navState.artists.bgVid.lq;
                     this.videoStarted = false;
                     this.playingVideo = true;
                     this.startVideo();
    }

  }

  componentWillUpdate = (nextProps) => {
    var query = this.props.navState.section.toLowerCase();
    if (query === "home"
        || query === "sounds"
        || query === "sights"
        || query === "synthesis"
        || query === "store"
        || query === "coalition"
        || query === "" ) {
          this.playingVideo = false;
          return;
        }
    else if (!this.playingVideo){
      if (nextProps.navState.artists !== undefined
          && this.props.navState.artists !== nextProps.navState.artists
          && this.props.navState.artists !== undefined
          && this.props.navState.artists.bgVid !== undefined) {
        this.movie.src = (window.innerWidth > 900)
                     ? nextProps.navState.artists.bgVid.hq
                     : nextProps.navState.artists.bgVid.lq;
                     this.playingVideo = true;
      }
    }
  }

  renderVideoControls = () => {
      if (window.location.hash.split('/') < 2 )
      return (
        <div style={{opacity: (this.fullScreen) ? 0 : 1, fontFamily: this.props.navState.artists.fonts.menu}} className={"artistMenu"}>
          <div style={{height: "40px",width: "100%", bottom: "25px"}}>
              <a style={{margin: "5px", bottom:"15px", position: "relative", height: "40px", width: "100%", display: 'inline-block'}}>
              <img onClick ={(e) => { e.stopPropagation();
                                      this.playMuted = false;
                                      this.videoStarted = false;
                                      this.startVideo(); }}
                   style={{width: "52px", height: "32px"}} src={this.replayButton.src}/>

              <img onClick ={(e) => { e.stopPropagation();
                                      if (!this.videoStarted) { this.videoStarted = true; this.startVideo(); }
                                      else { this.videoStarted = false; this.stopVideo();}}}
                   style={{width: "52px", height: "32px"}}
                   src={(this.videoStarted)
                        ? this.pauseButton.src
                        : this.playButton.src}/>

              <img onClick ={(e) => { e.stopPropagation(); this.playMuted = !this.playMuted; this.setState({muted: this.playMuted}); }}
                   style={{width: "52px", height: "32px"}}
                   src={(!this.playMuted)
                         ? this.soundOnButton.src
                         : this.soundOffButton.src}/>

              <img onClick ={(e) => { e.stopPropagation(); this.fullScreen = true; this.startVideo();}}
                   style={{width: "52px", height: "32px"}}
                   src={this.fullscreenButton.src}/>
              </a>
          </div>
        </div>
        );
  }

  renderArtistPage = () => {

    if (!this.props.navState.artists
     || !this.props.navState.artists.fonts
     || !this.props.navState.artists.siteStyle) return (<div></div>)
    else {
      if (!this.movie.src) {
        this.movie.src = (window.innerWidth > 900)
                       ? this.props.navState.artists.bgVid.hq
                       : this.props.navState.artists.bgVid.lq;
      }
      return (
              <React.Fragment>
              <div onClick={() => {this.fullScreen = false; this.startVideo();}  }
                   style={{ backgroundColor: (this.props.navState.artists.siteStyle.theme === "dark") ? "black" : "white",
                            zIndex: (this.fullScreen) ? 2000 : -1000,
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden"}}>
                <video
                       ref={ref => this.videoRef = ref}
                       className={"bgVid"+((this.fullScreen) ? " solo" : "") }
                       width={window.innerWidth}
                       height={window.innerHeight}
                       id="background-video"
                       muted={(this.playMuted)} loop autoPlay
                       src={this.movie.src}>

                  Your browser does not support the video tag.
                </video>
                {this.props.navState.artists.images.map((item, i) => {
                  return(<img key={i} style={{...item.style, ...{zIndex: (this.fullScreen) ? -1000 : 1000}}}
                              className={item.class}
                              src={item.src}/>)
                })}
              </div>
              <div style={{position: 'relative',
                           height: '210px',
                           color: (this.props.navState.artists.siteStyle.theme === "dark") ? "white" : "black"}}>
                <ArtistBio socialIcons={this.props.socialIcons} logoAsTitle={this.props.navState.artists.logo.useAsTitle}/>
                { this.renderVideoControls() }
              </div>
              <ArtistMenu streamingIcons={this.props.streamingIcons}/>
              <SocialBox icons={this.props.socialIcons} fullscreen={this.fullScreen} artistInfo={this.props.navState.artists}/>
            </React.Fragment>)
          }
  }

  swapPost = (selected) => {
    var newContent = eval('this.props.navState.content.new.'+selected.Section);
    var oldContent = eval('this.props.navState.content.old.'+selected.Section);
    this.props.navState.content.new = selected;
  }

  updatePost = (changedPost) => {
    var postType      = "";
    var section       = changedPost.Section.toLowerCase();
    var postObject       = new Array();
    var numberInSection = 0;

    (changedPost.archived) ? postType = "old" : postType = "new";

    postObject = eval('this.props.navState.content.'+postType+'.'+section);
    postObject.map((item, i) => {
      if (item.id === changedPost.id) numberInSection = i;
    })

    var currentState = this.props.navState.content;
    currentState[postType][section][numberInSection] = changedPost;

    this.props.confirmContentLoaded(currentState);
  }

  renderSectionPanel = (orientation, newPost, oldPosts, css, solo) => {
    return(
      <SectionPanel orientation={orientation}
                    highlightContent={newPost}
                    oldContent={oldPosts}
                    currentClass={css}
                    isSolo={solo}/>
    );
  }

  startVideo = () => {
    if (!this.videoStarted) {
      this.videoStarted = true;
      this.videoRef.pause();
      var tempOpacity = this.videoRef.style.opacity;
      this.videoRef.currentTime = 0;
      this.props.playingVideo(!this.props.navState.playingVideo);
      setTimeout(() => {
        this.videoRef.play();
      }, 0);
    } else {
      this.props.playingVideo(!this.props.navState.playingVideo);
      setTimeout(() => {
        this.videoRef.play();
      }, 0);
    }
  }

  stopVideo = () => {
    this.videoRef.pause();
    this.props.playingVideo(!this.props.navState.playingVideo);
  }

  render() {
    if ( this.props.navState.content === undefined
      || this.props.navState.section === undefined) return (

        <div className={"updatesPanel"}>
          <SplashScreen text={"Loading Main Page"}/>
          <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
          <SectionPanel orientation={"rtl"} currentClass={"sectionPanel hidden"}/>
          <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
        </div>);
    switch (this.props.navState.section.split('/')[0]) {
      case ("Home" || "" || undefined): {
        return(
          <div className={"updatesPanel"}>
            {this.renderSectionPanel('ltr',
                                     this.props.navState.content.new.sounds[0],
                                     this.props.navState.content.old.sounds,
                                     'sectionPanel',false)}
            {this.renderSectionPanel('rtl',
                                     this.props.navState.content.new.sights[0],
                                     this.props.navState.content.old.sights,
                                     'sectionPanel',false)}
           {this.renderSectionPanel('ltr',
                                     this.props.navState.content.new.synthesis[0],
                                     this.props.navState.content.old.synthesis,
                                     'sectionPanel',false)}
          </div>
      ); break; }
      case ("Coalition")   :  { return(<ComicReader/>); break; }
      case ("Sounds")   :  {
        return (
          <div className={"updatesPanel"}>
            {this.renderSectionPanel('ltr',
                                     this.props.navState.content.new.sounds[0],
                                     this.props.navState.content.old.sounds,
                                     'sectionPanel',true)}
            <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
            <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
          </div>);
          break;
      }
      case ("Sights")   :  {
        return (
          <div className={"updatesPanel"}>
            <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
            {this.renderSectionPanel('ltr',
                                     this.props.navState.content.new.sights[0],
                                     this.props.navState.content.old.sights,
                                     'sectionPanel',true)}
            <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
          </div>);
          break;
      }
      case ("Synthesis")   :  {
        return (
          <div className={"updatesPanel"}>
            <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
            <SectionPanel orientation={"ltr"} currentClass={"sectionPanel hidden"}/>
            {this.renderSectionPanel('ltr',
                                     this.props.navState.content.new.synthesis[0],
                                     this.props.navState.content.old.synthesis,
                                     'sectionPanel',true)}
          </div>); break;
      }
      case ("Store"): {
        return(
            <iframe className={"CataphonicStore"} src="https://cataphonic.store"></iframe>
        );
        break;
      }
      default: {return(<React.Fragment> {this.renderArtistPage()} </React.Fragment>); break;}
    }
  }
}

const mapStateToProps = state => ({
 ...state
})

const mapDispatchToProps = dispatch => ({
    updateWindowSize: windowSize => dispatch(updateWindowSize(windowSize)),
    updateNavbar: section => dispatch(updateNavbar(section)),
    confirmContentLoaded: content => dispatch(confirmContentLoaded(content)),
    playingVideo: playing => dispatch(playingVideo(playing))
})

const UpdatesPanel = connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdatesPanel);
export default UpdatesPanel;
