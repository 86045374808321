import React, { Component } from 'react';
import { connect } from 'react-redux';
import DDDDStats from './DDDDStats'
import LogoBox from './LogoBox.js';
import NavBox  from './NavBox.js';
import scrollToComponent from 'react-scroll-to-component';
import { updateNavbar } from './actions/simpleAction.js'


class ConnectedDDDD extends Component {

  constructor(props){
    super(props);

    const url = window.location.href;
    const lastPart= url.split("/").pop();

    this.scrolledTo = false;

    this.url = url;
    this.lastPart = lastPart;
    this.hashNav  = lastPart.split("#").pop();

    this.statsSection = React.createRef();
    this.playSection  = React.createRef();

  }


  render() {

    if (this.hashNav != "" && !this.scrolledTo) {
      if (this.hashNav == "Stats") scrollToRef(this.statsSection);
      if (this.hashNav == "Play")  scrollToRef(this.playSection);
      this.scrolledTo = true;
    }

    return(
      <div>
        <LogoBox color={"white"} shadow={"n"} />
        <NavBox color={"white"} background={"transparent"} mode={"DDDD"}/>
        <iframe ref={this.playSection} src="https://i.simmer.io/@Xihro/~8e7e7a21-b2cc-f9d3-c61e-dd6da6b91760" style={{ width: '100vw', height: '100vh'}}></iframe>
        <DDDDStats ref={this.statsSection}/>
      </div>
    )
  }

}

const scrollToRef = (ref) => {
  console.log(ref);
  window.scrollTo(0, ref.current);
}
const mapDispatchToProps = dispatch => ({
  updateNavbar: section => dispatch(updateNavbar(section))
});

const DDDD = connect(null, mapDispatchToProps)(ConnectedDDDD);

export default DDDD;
