import React, { Component } from 'react'
import { connect } from 'react-redux'
var dbFuncs = require('./dbShorthand');

class ConnectedDBTestForm extends Component {

  constructor(props) {
    super(props);

    this.removeTestData    =    this.removeTestData.bind(this);
    this.insertTestData = this.insertTestData.bind(this);
    this.addAnalysisData = this.addAnalysisData.bind(this);
    this.addMiscData = this.addMiscData.bind(this);

    this.state = {
      username: '',
      method: '',
      dbName: '',
      collectionName: ''
    }
  }

  addMiscData = (event) => {
    event.preventDefault();
    dbFuncs.process('cataphonic','venues','updateOne',{ id: '0000000001', name: 'Performance Plus / RVP Studios', location: '221 Bull Hill Ln, West Haven, CT 06516', source: 'Manual', source_id: "N/A"},{ id: '0000000001'})
  }

  addAnalysisData = (event) => {
    event.preventDefault();

    let at = this.props.navState.params.sp_access_token;


    dbFuncs.process('cataphonic','tracks','distinct','id')
    .then ( d => {

           let fiftyCounter = 0;
           let batchOfFifty = [];
           batchOfFifty[fiftyCounter] = [];

           d.map((item, i) => {
             if (i > (50+(fiftyCounter * 50))) {
               fiftyCounter += 1;
               batchOfFifty[fiftyCounter] = [];}

               batchOfFifty[fiftyCounter].push(item);
           })

           batchOfFifty.map((item, i) => {
             fetch("https://api.spotify.com/v1/audio-features/?ids="+item.toString(),{
               method: 'GET',
               headers: {
                 'Authorization' : 'Bearer '+at
                 }
               })
               .then( d => { return d.json(); })
               .then( result => {
                 console.log(result);
               dbFuncs.process('cataphonic','analysis','updateMany',result.audio_features)
                 .then( f => console.log(f))
                 .catch(e => console.log(e));
               })
               .catch( e => console.log(e));
             })
           })

    .catch( e => {console.log("Failed"); console.log(e)});
  }

  removeTestData = (event) => {
    event.preventDefault();

    dbFuncs.process('cataphonic','tracks','deleteMany',{ $or: [
                                                                { date_updated: { $eq: '2019-06-NaN'}},
                                                                { date_updated: { $gt: dbFuncs.getDate('date')}} ]})
    .catch( e => console.log("Couldn't delete."));
}

  insertTestData = (event) => {
    event.preventDefault();

    dbFuncs.process('cataphonic','tracks','findAll',{date_updated: '2019-06-06'}, 7)
    .then( d => { let testData = [];
                  let count = 0;
                  d.map( (track, index) => {
                    if (count >= 100) {
                      dbFuncs.process('cataphonic','tracks','updateMany',testData)
                        .catch( e => console.log(e));
                      count = 0;
                      testData = [];
                    }

                    let initialPop = 0;

                    for (let i = 0; i < 21; i += 1) {

                      let plusOrMinus = Math.random() < 0.2 ? -0.1 : 0.7;

                      initialPop += Math.random() * 2 * plusOrMinus;

                      testData.push({ id: track.id,
                                      date_updated: dbFuncs.getDate('date',i+1),
                                      name: track.name,
                                      popularity: track.popularity + (initialPop),
                                      source_id: track.source_id,})
                     count += 1;
                  }
                })})
    .catch( e => {console.log(e)});
}

  render () {
    return (
        <form style={{position: 'absolute', top: '80px', left: '140px'}}>
          <input type="submit" value="Insert Test Data" onClick={this.insertTestData}   onSubmit={this.insertTestData}></input>
          <input type="submit" value="Remove Test Data" onClick={this.removeTestData}   onSubmit={this.removeTestData}></input>
          <input type="submit" value="Add Feature Data" onClick={this.addAnalysisData} onSubmit={this.addAnalysisData}></input>
          <input type="submit" value="Add Misc Data" onClick={this.addMiscData} onSubmit={this.addMiscData}></input>
        </form>
        )
    };
}

const mapStateToProps = state => ({
  ...state
})

const DBTestForm = connect(mapStateToProps, null)(ConnectedDBTestForm);

export default DBTestForm;
