var fetch = require('node-fetch')

module.exports = {
      pack: (dbn, cn, dbm, d, kv , src, srcid) =>{
        var db = dbn;
        var collectionName = cn;
        var dbMethod = dbm;
        var data = d;
        var queryValues = kv || null;
        var source = src || null;
        var source_id = srcid || null;

        return ({ db: db, collectionName: collectionName, dbMethod: dbMethod, data: data, queryValues: queryValues, source: source, source_id: source_id})
      },
      pad:  (n, width, z) => {
       z = z || '0';
       n = n + '';
       return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      },
      getDate: (type,offset) => {

        let today       = new Date();
        let daysBetween = (offset) ? offset : 0;
        let targetDate =  new Date(today.getTime() + (daysBetween * 24*60*60*1000))
        if (!offset || offset === null || offset === undefined) offset = 0;

        switch (type) {
          case ('date'): {
            let date  = targetDate.getFullYear()+'-'+module.exports.pad(targetDate.getMonth()+1,2)+'-'+module.exports.pad(targetDate.getDate(),2);
            return date;
          }
          case ('time'): {
            let time  = targetDate.getHours()+':'+module.exports.pad(targetDate.getMinutes(),2)+':'+module.exports.pad(targetDate.getSeconds(),2);
            return time;
          }
          default: return;
        }
      },
      process: (dbn, cn, dbm, d, kv, src, src_id) => {

        var request = module.exports.pack(dbn,cn,dbm,d,kv,src,src_id)

        return new Promise((resolve, reject)=>{
              fetch('https://cataphonic.studio:8889/dbHandler', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                  })
                  .then (result => {return result.json()})
                  .then  ( d => {resolve(d)})
                  .catch ( e => {reject(e)})
          }
        )
    }
  }
