import React, { Component } from 'react';
import { connect } from 'react-redux'
import { updateNavbar, updateArtists, setNowPlaying } from '../../actions/simpleAction.js'
import openSocket from 'socket.io-client';
import StreamingOptions from './StreamingOptions.js'
import SongBox from './SongBox.js'

const dbFuncs = require('../../Test/dbShorthand.js');

const sources = ['Home','Sounds','Sights','Synthesis','Store'];

function getTime(time) {
  if(!isNaN(time)) {
    return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
  }
}

class ConnectedAlbumBox extends React.Component {

    constructor (props) {
      super(props);
      this.state = { selectedSong: '', hidden: false}
    }

    renderAlbumPanel = () => {
      if (this.props.album.numTracks > 1) return(<div style={{maxHeight: "40px",minHeight: "40px", height: "40px"}} className={"songBox"}>
        <img style={{opacity: 0.2, position: "absolute", height: '100%',maxHeight: '40px',minHeight: "40px", width: "100%", left: 0, objectFit: 'cover'}} src={this.props.album.images.mq.url} className={"songBoxImage"}/>
        <div onClick={() => {this.setState({hidden: !this.state.hidden})}}style={{top: 0,
                     bottom: 0,
                     margin: 'auto',
                     width: "100%",
                     position: "absolute",
                     maxHeight: '40px',
                     minHeight: "40px",
                     height: "40px",
                     left: 0,
                     fontSize: '28px',
                     right: 0,
                     textAlign: 'center'}} className={"songBoxName"}>
          {this.props.album.name.toUpperCase()+" | "+this.props.album.numTracks+" songs | "+this.props.album.releaseDate}
        </div>
      </div>)
    }

    renderSongPanels = () => {
        if (this.props.album.songs !== undefined && this.props.album.songs.length > 1) {

      } else if (this.props.album.songs !== undefined) {
            return(<SongBox streamingIcons={this.props.streamingIcons} albumCover={this.props.album.images.lq.url} song={this.props.album.songs[0]}/>);
      }

    }
    render() {
        return(
          <div style={{ overflow: 'hidden', maxHeight: `${window.innerHeight - 350}px` }} className={"songPanel"}>
            {this.renderAlbumPanel()}
            <div className={"songPanel"+((!this.state.hidden) ? "" : " hidden")}>
              {this.props.album.songs.map((item, i) => {
                return(<SongBox streamingIcons={this.props.streamingIcons}
                                key={i}
                                albumCover={this.props.album.images.lq.url}
                                song={item}/>)})
              }
            </div>
          </div>
        )
      }
}
const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  updateArtists: artistInfo => dispatch(updateArtists(artistInfo)),
  setNowPlaying: songPlaying => dispatch(setNowPlaying(songPlaying))
})

const AlbumBox = connect(mapStateToProps,mapDispatchToProps)(ConnectedAlbumBox)

export default AlbumBox;
